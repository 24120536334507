import React from "react";
import { Navigate } from "react-router-dom";

//Dashboard
import Dashboard from "../pages/Dashboard";

//login
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Developer from "../pages/Developer";
import Report from "../pages/Report";
import Summary from "../pages/Summary";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/summary", component: <Summary /> },
  { path: "/report", component: <Report /> },
  { path: "/developer", component: <Developer /> },
  { path: "/index", component: <Dashboard /> },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
];

export { authProtectedRoutes, publicRoutes };
