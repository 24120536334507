import React, { useState } from "react";
import { Card, CardBody, CardHeader} from "reactstrap";
import { useSelector } from "react-redux";
import ColumnChart from "./ColumnChart";
import { REPORT_PERIOD } from "../../common/constants";
import { useTranslation } from "react-i18next";

function BedFallSummaryChart() {
    const { t } = useTranslation();
    const [bedFallSummaryPeriod, setBedFallSummaryPeriod] = useState("");
    const bedFallSummaryData = useSelector(state => bedFallSummaryPeriod === REPORT_PERIOD.M1?state.Summary.bedFall.M1:bedFallSummaryPeriod === REPORT_PERIOD.M3?state.Summary.bedFall.M3:bedFallSummaryPeriod === REPORT_PERIOD.M6?state.Summary.bedFall.M6:state.Summary.bedFall.ALL);
    const xFont = (bedFallSummaryData.form === REPORT_PERIOD.M1 || bedFallSummaryData.form === REPORT_PERIOD.M3)?'10px':'12px';
    return (
        <React.Fragment>
            <Card className="summary-card">
                <CardHeader className="summary-card-header">
                    <h4 className="card-title mb-0 summary-title">{t('PageSummary.BedFall.Title')}</h4>
                    <div className="d-flex flex-row align-item-center">
                        <div className={bedFallSummaryPeriod === REPORT_PERIOD.M1 ? "summary-period-box summary-period-box-selected" : "summary-period-box"} onClick={() => {
                            setBedFallSummaryPeriod(REPORT_PERIOD.M1)
                        }}>{t("PageSummary.Period.1M")}</div>
                        <div className={bedFallSummaryPeriod === REPORT_PERIOD.M3 ? "summary-period-box summary-period-box-selected" : "summary-period-box"} onClick={() => {
                            setBedFallSummaryPeriod(REPORT_PERIOD.M3)
                        }}>{t("PageSummary.Period.3M")}</div>
                        <div className={bedFallSummaryPeriod === REPORT_PERIOD.M6 ? "summary-period-box summary-period-box-selected" : "summary-period-box"} onClick={() => {
                            setBedFallSummaryPeriod(REPORT_PERIOD.M6)
                        }}>{t("PageSummary.Period.6M")}</div>
                        <div className={bedFallSummaryPeriod === REPORT_PERIOD.ALL ? "summary-period-box summary-period-box-selected" : "summary-period-box"} onClick={() => {
                            setBedFallSummaryPeriod(REPORT_PERIOD.ALL)
                        }}>{t("PageSummary.Period.ALL")}</div>
                    </div>
                </CardHeader>
                <CardBody>
                    <ColumnChart dataColors='["--vz-danger"]' xData={bedFallSummaryData.x} yData={bedFallSummaryData.y} yName={t('PageSummary.BedFall.Subtitle')} xFont={xFont}/>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default BedFallSummaryChart;
