import { Button, Card, CardBody, Modal } from "reactstrap";
import DashboardSectionHeader from "./DashboardSectionHeader";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import HistoryCard from "./HistoryCard";
import { useEffect, useState } from "react";
import { getAllPendingEvents } from "../../slices/event/thunk";
import EventEditModal from "../../Components/Common/EventEditModal";


function HistorySection() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const totalEvents = useSelector((state => state.Event.pending.totalEvents));
    const events = useSelector((state => state.Event.pending.events));
    const [editEvent, setEditEvent] = useState(null);
    const [isOpenErrorModal, setOpenErrorModal] = useState(false);
    const isMQConnected = useSelector(state => state.Device.isMQConnected);
    const isSocketConnected = useSelector(state => state.Device.isSocketConnected);
    useEffect(() => {
        if (isMQConnected && isSocketConnected) {
            dispatch(getAllPendingEvents());
        }
    }, [isMQConnected, isSocketConnected]);

    return (
        <Card className="section-card">
            <CardBody className="section-cardbody">
                <div className="d-flex flex-column section-container">
                    <div className="d-flex flex-row justify-content-between">
                        <DashboardSectionHeader title={t('PageMain.History.Title')} bed={totalEvents.BED + t('Common.Space1') + (totalEvents.BED > 1 ? t('Common.Beds') : t('Common.Bed'))} toilet={totalEvents.TOILET + t('Common.Space1') + (totalEvents.TOILET > 1 ? t('Common.Toilets') : t('Common.Toilet'))} />
                        {/*<i className="bx bx-dots-horizontal-rounded dash-device-header-icon p-2"></i>*/}
                    </div>
                    <div className="card-container">
                    {events.map((data) => {
                        return (
                            <HistoryCard key={data} data={data} openEditModal={(eventID) => { setEditEvent(eventID) }} />
                        );
                    })}
                    </div>
                </div>
                {editEvent !== null?
                <EventEditModal
                    show={editEvent !== null}
                    onCloseClick={(isDone) => {
                        if (isDone !== undefined) {
                            if (!isDone) {
                                setOpenErrorModal(true);
                            }
                        }
                        setEditEvent(null)
                    }}
                    data={editEvent}
                />:null}
            </CardBody>
            <Modal
                isOpen={isOpenErrorModal}
                toggle={() => {
                    setOpenErrorModal(false);
                }}
                centered
            >
                <div className="modal-body text-center p-5">
                    <div className="mt-4">
                        <h4 className="mb-3">{t('EditEventInfoModal.Error')}</h4>
                        <p className="text-muted mb-4">{t('EditEventInfoModal.ErrorDescription')}</p>
                        <div className="hstack gap-2 justify-content-center">
                            <Button color="light" onClick={() => setOpenErrorModal(false)}>{t('Common.Btn.Close')}</Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </Card>
    );
}

export default HistorySection;