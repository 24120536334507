import { loginSuccess, logoutUserSuccess, apiError, reset_login_flag } from './reducer';
import { POST_LOGIN } from '../../../helpers/url_helper';
import { APIClient } from '../../../helpers/api_helper';
import { profileSuccess } from '../profile/reducer';
import { resetState as resetDeviceSlice } from '../../device/reducer';
import { resetState as resetEventSlice } from '../../event/reducer';
import { resetState as resetLoginSlice } from '../../auth/login/reducer';
import { resetState as resetProfileSlice } from '../../auth/profile/reducer';
import { resetState as resetSummarySlice } from '../../summary/reducer';

const api = new APIClient();

export const loginUser = (user, history) => async (dispatch) => {
  try {    
    var data = await api.create(POST_LOGIN, {userid: user.username, pwd: user.password});
    if (data) {
      sessionStorage.setItem("authUser", JSON.stringify(data.data));
      
      dispatch(loginSuccess(data));
      dispatch(profileSuccess({status: "logged", data: data.data.user}));
      history('/dashboard')
    }
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
};

export const logoutUser = () => async (dispatch) => {
  try {
    sessionStorage.removeItem("authUser");

    dispatch(logoutUserSuccess(true));
    dispatch(resetDeviceSlice());
    dispatch(resetEventSlice());
    dispatch(resetLoginSlice());
    dispatch(resetProfileSlice());
    dispatch(resetSummarySlice());

  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginFlag = () => async (dispatch) =>{
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};