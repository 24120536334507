import flaggb from "../assets/images/flags/gb.svg";
import flaghk from "../assets/images/flags/hk.svg";

const languages = {
  en: {
    label: "English",
    flag: flaggb,
  },
  cn: {
    label: "香港",
    flag: flaghk,
  },
}

export default languages
