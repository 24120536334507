import React, { useEffect, useState } from "react";
import { Container, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import ReportTable from "./ReportTable";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import ReportListTable from "./ReportListTable";
import { useDispatch, useSelector } from "react-redux";
import { getStaticFloorPlan, getStaticMonitorType, getStaticReportMonitorStatus } from "../../slices/staticData/thunk";

export const REPORT_TAB_OVERVIEW = "1";
export const REPORT_TAB_SAVE_REPORT = "2";

function Report() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [reportTab, setreportTab] = useState(REPORT_TAB_OVERVIEW);
  const selectedReportID = useSelector(state => state.Event.report.reportID);
  const isModifiedReportID = useSelector(state => state.Event.report.isModifiedReportID);
  const toggleCustom = (tab) => {
    if (reportTab !== tab) {
      setreportTab(tab);
    }
  };

  useEffect(() => {
    dispatch(getStaticFloorPlan());
    dispatch(getStaticMonitorType());
    dispatch(getStaticReportMonitorStatus());
}, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="p-0">
          <Nav tabs className="nav nav-tabs nav-tabs-custom nav-primary">
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: reportTab === REPORT_TAB_OVERVIEW,
                })}
                onClick={() => {
                  toggleCustom(REPORT_TAB_OVERVIEW);
                }}
              >
                {(selectedReportID !== undefined && selectedReportID!== null && isModifiedReportID? "* ":"") + t('PageReport.Tab.Overview') + (selectedReportID!== undefined && selectedReportID!== null?" [" + selectedReportID.name+"]":"")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: reportTab === REPORT_TAB_SAVE_REPORT,
                })}
                onClick={() => {
                  toggleCustom(REPORT_TAB_SAVE_REPORT);
                }}
              >
                {t('PageReport.Tab.SavedReports')}
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent
            activeTab={reportTab}
            className="text-muted report-tab-container"
          >
            <TabPane tabId={REPORT_TAB_OVERVIEW}>
              <div className="d-flex">
               <ReportTable toggleTag={(tagID)=>{toggleCustom(tagID)}}/>
              </div>
            </TabPane>
            <TabPane tabId={REPORT_TAB_SAVE_REPORT}>
              <div className="d-flex">
                <ReportListTable toggleTag={(tagID)=>{toggleCustom(tagID)}}/>
              </div>
            </TabPane>
          </TabContent>

        </Container>
      </div>
    </React.Fragment>
  );
};

export default Report;
