import { Button, Card, CardBody, UncontrolledTooltip } from "reactstrap";
import { useTranslation } from "react-i18next";
import { getDisplayFloor, getDisplayLocation, getDisplayRoom } from "../../helpers/display_help";
import { CameraIcon, EditIcon, EventBedIcon, EventToiletEmptyIcon, EventToiletIcon, Monitor24Icon, MonitorAppliedRuleIcon, MonitorNightIcon, MonitorSelfDefinedIcon, PinIcon } from "../../Components/Common/SVG";
import colors from "../../assets/scss/config/saas/_colors.scss";
import { MONITOR_PERIOD, MONITOR_STATUS, MONITOR_TYPE } from "../../common/constants";
import DeviceTimer from "./DeviceTimer";
import { useSelector } from "react-redux";
import { pinDevice } from "../../services/device";
import { Fragment } from "react";


const getPinIcon = (data) => {
    if (data.pin) {
        return <PinIcon scale={1.2} fill={colors.pinOn}/>;
    }
    else {
        return <PinIcon scale={1.2} fill={colors.off}/>;
    }
} 

const getDeviceStatusIcon = (data, t) => {
    let item = null;
    if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
        if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.TOILET.IDLE) {
            item = <EventToiletEmptyIcon scale={1.2} fill={data.isConnected?colors.eventToiletVacant:colors.off}/>;
        }
        else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING) {
            item = <EventToiletIcon scale={1.2} fill={data.isConnected?colors.eventToiletNormal:colors.off}/>;
        }      
    }
    else {
        if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.BED.IDLE) {
            item = <EventBedIcon scale={1.2} fill={data.isConnected?colors.eventBedNormal:colors.off}/>;
        } 
    }

    if (data.currentMonitorStatus === null) {
        return  <Fragment><Button className="dash-tooltip-button" id={"dashTooltip-"+data.id}>{item}</Button>
        <UncontrolledTooltip style={{backgroundColor: "#666666"}} placement="top" target={"dashTooltip-"+data.id}>{t('Common.MonitorStatusType.NULL')}</UncontrolledTooltip></Fragment>;
    }
    return <Fragment><Button className="dash-tooltip-button" id={"dashTooltip-"+data.id}>{item}</Button>
            <UncontrolledTooltip style={{backgroundColor: "#666666"}} placement="top" target={"dashTooltip-"+data.id}>{t('Common.MonitorStatusType.'+data.currentMonitorStatus)}</UncontrolledTooltip></Fragment>;
} 

const getDeviceConnectionIcon = (data, t) => {
    if (data.isConnected) {
        if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
            return <Fragment><Button className="dash-tooltip-button" id={"dashTooltip-connection-"+data.id}><CameraIcon scale={1.2} fill={colors.cameraToiletNormal}/></Button>
            <UncontrolledTooltip style={{backgroundColor: "#666666"}} placement="top" target={"dashTooltip-connection-"+data.id}>{t('Common.DeviceConnection.Normal')}</UncontrolledTooltip></Fragment>;
        }
        else {
            return <Fragment><Button className="dash-tooltip-button" id={"dashTooltip-connection-"+data.id}><CameraIcon scale={1.2} fill={colors.cameraBedNormal}/></Button>
            <UncontrolledTooltip style={{backgroundColor: "#666666"}} placement="top" target={"dashTooltip-connection-"+data.id}>{t('Common.DeviceConnection.Normal')}</UncontrolledTooltip></Fragment>;
        }
    }
    else {
        return <Fragment><Button className="dash-tooltip-button" id={"dashTooltip-connection-"+data.id}><CameraIcon scale={1.2} fill={colors.cameraError}/></Button>
        <UncontrolledTooltip style={{backgroundColor: "#666666"}} placement="top" target={"dashTooltip-connection-"+data.id}>{t('Common.DeviceConnection.Stopped')}</UncontrolledTooltip></Fragment>;
    }
} 

const getMonitorPeriod = (data, t) => {
    let size = 1.3;
    let fill = colors.toilet;
    if (data.monitorTypeID === MONITOR_TYPE.BED) {
        fill = colors.bed;
    }
    fill = data.isConnected?fill:colors.off;
    let item = null;
    if (data.monitorPeriod === MONITOR_PERIOD["24hours"]) {
        item = <Monitor24Icon scale={size} fill={fill}/>;
    }
    else if (data.monitorPeriod === MONITOR_PERIOD.MidNight) {
        item = <MonitorNightIcon scale={size} fill={fill}/>;
    }
    else if (data.monitorPeriod === MONITOR_PERIOD.SelfDefined) {
        item = <MonitorSelfDefinedIcon scale={size} fill={fill}/>;
    }
    else if (data.monitorPeriod === MONITOR_PERIOD.AppliedRule) {
        item = <MonitorAppliedRuleIcon scale={size} fill={fill}/>;
    }
    else {
        item = <div></div>;
    }

    return <Fragment><Button className="dash-tooltip-button" id={"dashTooltip-monitor-period-"+data.id}>{item}</Button>
    <UncontrolledTooltip style={{backgroundColor: "#666666"}} placement="top" target={"dashTooltip-monitor-period-"+data.id}>{t('Common.MonitorPeriod.'+data.monitorPeriod)}</UncontrolledTooltip></Fragment>;
} 

const getCarerName = (data) => {
    if (data.carerName !== null) {
        return <div className="dash-device-card-text-off">{data.carerName}</div>;
    }
    else {
        if (data.isConnected) {
            if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
                if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.TOILET.IDLE) {
                    return <div className="dash-device-card-text-off">-</div>
                }
                else {
                    return <div>-</div>;
                }
            }
            else {
                return <div></div>
            }
        }
        else {
            return <div className="dash-device-card-text-off">-</div>;
        }
    }
} 

const getFloor = (data, t) => {
    let floor = getDisplayFloor(data.floorName, t);
    if (data.isConnected) {
        if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
            if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.TOILET.IDLE) {
                return <div className="dash-device-card-text-off">{floor}</div>
            }
            else {
                return <div>{floor}</div>;
            }
        }
        else {
            if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.BED.IDLE) {
                return <div className="dash-device-card-text-off">{floor}</div>
            }
            else {
                return <div>{floor}</div>;
            }
        }
    }
    else {
        return <div className="dash-device-card-text-off">{floor}</div>
    }
}

const getRoom = (data, t) => {
    let room = getDisplayRoom(data, t);
    if (data.isConnected) {
        if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
            if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.TOILET.IDLE) {
                return <div className="dash-device-card-text-off">{room}</div>
            }
            else {
                return <div>{room}</div>
            }
        }
        else {
            if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.BED.IDLE) {
                return <div className="dash-device-card-text-off">{room}</div>
            }
            else {
                return <div>{room}</div>
            }
        }
    }
    else {
        return <div className="dash-device-card-text-off">{room}</div>
    }
}

const getLocation = (data, t) => {
    let location = getDisplayLocation(data, t);
    if (data.isConnected) {
        if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
            if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.TOILET.IDLE) {
                return <div className="dash-device-card-text-off">{location}</div>
            }
            else {
                return <div>{location}</div>
            }
        }
        else {
            if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.BED.IDLE) {
                return <div className="dash-device-card-text-off">{location}</div>
            }
            else {
                return <div>{location}</div>
            }
        }
    }
    else {
        return <div className="dash-device-card-text-off">{location}</div>
    }
}

const getDescription = (data, t) => {
    if (data.isConnected) {
        if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
            if (data.currentMonitorStatus === null) {
                return <div className="dash-device-card-text-toilet-idle">-</div>;
            }
            else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.IDLE) {
                return <div className="dash-device-card-text-toilet-idle">{t('Common.MonitorStatusTypeShortForm.'+data.currentMonitorStatus)}</div>;
            }
            else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING) {
                return <div className="dash-device-card-text-toilet-using">{t('Common.MonitorStatusTypeShortForm.'+data.currentMonitorStatus)}</div>;
            }
            else {
                return <div></div>
            }
        }
        else {
            if (data.currentMonitorStatus === null) {
                return <div className="dash-device-card-text-toilet-idle">-</div>;
            }
            else if (data.currentMonitorStatus === MONITOR_STATUS.BED.IDLE) {
                return <div className="dash-device-card-text-toilet-idle">{t('Common.MonitorStatusTypeShortForm.'+data.currentMonitorStatus)}</div>;
            }
            else {
                return <div></div>
            }
        }
    }
    else {
        return <div className="dash-device-card-text-nosignal">{t('Common.Connection.Off')}</div>
    }
}

const getEditIcon = (data) => {
    let size = 16;
    if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
        return <div></div>
    }
    else {
        //return <EditIcon size={size} fill={colors.offText} />
        return <div></div>
    }
}

function DeviceCard(props) {
    const { t } = useTranslation();
    const deviceID = props.data;    
    const data = useSelector((state => state.Device.devices[deviceID]));

    const pinRecord = (monitorLocationDeviceID, isPin) => {
        pinDevice(monitorLocationDeviceID, isPin);
    }

    if (data.isShow === undefined || data.isShow) {
        return (
            <Card className="dash-device-card">
                <CardBody className="dash-device-cardbody">
                    <div className="d-flex flex-row flex-wrap dash-device-container">
                        <div style={{flex: 1, minWidth: 30}} className="dash-device-card-icon d-flex flex-column align-items-center justify-content-center" onClick={()=>{pinRecord(data.id, !data.pin)}}>
                            {getPinIcon(data)}
                        </div>
                        <div style={{flex: 1, minWidth: 30}} className="dash-device-card-icon d-flex flex-column align-items-center justify-content-center">
                            {getDeviceStatusIcon(data, t)}
                        </div>
                        <div style={{flex: 1, minWidth: 30}} className="dash-device-card-icon d-flex flex-column align-items-center justify-content-center">
                            {getDeviceConnectionIcon(data, t)}
                        </div>
                        <div style={{flex: 1, minWidth: 30}} className="dash-device-card-icon d-flex flex-column align-items-center justify-content-center">
                            {getMonitorPeriod(data, t)}
                        </div>
                        <div style={{flex: 1, minWidth: 100}} className="dash-device-card-text d-flex flex-column align-items-center justify-content-center">
                            {getFloor(data, t)}
                        </div>
                        <div style={{flex: 1, minWidth: 100}} className="dash-device-card-text d-flex flex-column align-items-center justify-content-center">
                            {getRoom(data, t)}
                        </div>
                        <div style={{flex: 1, minWidth: 100}} className="dash-device-card-text d-flex flex-column align-items-center justify-content-center">
                            {getLocation(data, t)}
                        </div>
                        <div style={{flex: 1, minWidth: 100}} className="dash-device-card-text d-flex flex-column align-items-center justify-content-center">
                            {getCarerName(data)}
                        </div>
                        <div style={{flex: 1, minWidth: 100}} className="dash-device-card-text d-flex flex-column align-items-center justify-content-center">
                            {getDescription(data, t)}
                        </div>
                        <div style={{flex: 1, minWidth: 100}} className="dash-device-card-text d-flex flex-column align-items-center justify-content-center">
                            <DeviceTimer data={data}/>
                        </div>                    
                        <div style={{flex: 1, minWidth: 100}} className="dash-device-card-icon d-flex flex-column align-items-center justify-content-center">
                            {getEditIcon(data)}
                        </div>
                    </div>
                </CardBody>
            </Card>
        );
    }
    else {
        return null;
    }
}

export default DeviceCard;