import React, { useState } from "react";
import { Card, CardBody, CardHeader} from "reactstrap";
import { useSelector } from "react-redux";
import { REPORT_PERIOD } from "../../common/constants";
import { useTranslation } from "react-i18next";
import LineChart from "./LineChart";

function ToiletArriveTimeSummaryChart() {
    const { t } = useTranslation();
    const [toiletSummaryPeriod, setSummaryPeriod] = useState("");
    const summaryData = useSelector(state => toiletSummaryPeriod === REPORT_PERIOD.M1?state.Summary.toiletArrival.M1:toiletSummaryPeriod === REPORT_PERIOD.M3?state.Summary.toiletArrival.M3:toiletSummaryPeriod === REPORT_PERIOD.M6?state.Summary.toiletArrival.M6:state.Summary.toiletArrival.ALL);
    const xFont = (summaryData.form === REPORT_PERIOD.M1 || summaryData.form === REPORT_PERIOD.M3)?'10px':'12px';
    let series = [{name: t('PageSummary.AverageHandleTime.Fall'), data: summaryData.y2}, {name:t('PageSummary.AverageHandleTime.OT'), data:summaryData.y1}];
    return (
        <React.Fragment>
            <Card className="summary-card">
                <CardHeader className="summary-card-header">
                    <h4 className="card-title mb-0 summary-title">{t('Common.Toilet')+ t('PageSummary.AverageHandleTime.Title')}</h4>
                    <div className="d-flex flex-row align-item-center">
                        <div className={toiletSummaryPeriod === REPORT_PERIOD.M1 ? "summary-period-box summary-period-box-selected" : "summary-period-box"} onClick={() => {
                            setSummaryPeriod(REPORT_PERIOD.M1)
                        }}>{t("PageSummary.Period.1M")}</div>
                        <div className={toiletSummaryPeriod === REPORT_PERIOD.M3 ? "summary-period-box summary-period-box-selected" : "summary-period-box"} onClick={() => {
                            setSummaryPeriod(REPORT_PERIOD.M3)
                        }}>{t("PageSummary.Period.3M")}</div>
                        <div className={toiletSummaryPeriod === REPORT_PERIOD.M6 ? "summary-period-box summary-period-box-selected" : "summary-period-box"} onClick={() => {
                            setSummaryPeriod(REPORT_PERIOD.M6)
                        }}>{t("PageSummary.Period.6M")}</div>
                        <div className={toiletSummaryPeriod === REPORT_PERIOD.ALL ? "summary-period-box summary-period-box-selected" : "summary-period-box"} onClick={() => {
                            setSummaryPeriod(REPORT_PERIOD.ALL)
                        }}>{t("PageSummary.Period.ALL")}</div>
                    </div>
                </CardHeader>
                <CardBody>
                    <LineChart dataColors='["--vz-danger", "--vz-warning"]' xData={summaryData.x} seriesData={series} xFont={xFont}/>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default ToiletArriveTimeSummaryChart;
