import { Table } from "reactstrap";
import { useTranslation } from "react-i18next";
import { getDisplayDate, getDisplayDatetime, getDisplayDatetimeFromTimestamp, getDisplayFloor, getDisplayLocation, getDisplayRoom, getDisplayTime } from "../../helpers/display_help";
import { useSelector } from "react-redux";
import { MONITOR_STATUS } from "../../common/constants";
import colors from "../../assets/scss/config/saas/_colors.scss";
import { Fragment, useState } from "react";

const getStatus = (value, t) => {
    let boxColor = colors.handledDanger;
    if (value === MONITOR_STATUS.TOILET.FALL || value === MONITOR_STATUS.BED.FALL) {
        boxColor = colors.handledDanger;
    }
    else if (value === MONITOR_STATUS.TOILET.USING_OT) {
        boxColor = colors.handledWarning;
    }
    else if  (value === MONITOR_STATUS.TOILET.USING) {
        boxColor = colors.eventToiletNormal;
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ backgroundColor: boxColor }} className="report-event-eventType mr-3 align-self-center">{t('Common.MonitorStatusTypeShortForm.' + value)}</div>
        </div>
    );
}

function ReportRow(props) {
    const { t } = useTranslation();
    const eventID = props.eventID;
    const data = useSelector((state => state.Event.report.events[eventID]));
    const changes = useSelector((state => state.Event.report.changes));
    const [isCollapse, setCollapse] = useState(true);

    return (
        <Fragment>
            <tr>
                <td className="fw-medium collapse-icon" onClick={() => { setCollapse(!isCollapse); }}>
                    {(data["changeID"].length === 0 || data["eventType"] === MONITOR_STATUS.TOILET.USING)?null:isCollapse ?
                        <i className="bx bxs-plus-square"></i> : <i className="bx bxs-minus-square"></i>}
                </td>
                <td className="fw-medium">{data["id"]}</td>
                <td>{getDisplayDate(data["startTime"])}</td>
                <td>{getDisplayTime(data["startTime"])}</td>
                <td>{getDisplayFloor(data["floorName"], t)}</td>
                <td>{getDisplayRoom(data, t)}</td>
                <td>{getDisplayLocation(data, t)}</td>
                <td>{getStatus(data["eventType"], t)}</td>
                <td>{data["endTime"] === 0?"-":getDisplayDatetimeFromTimestamp(data["endTime"] - data["startTime"])}</td>
                <td>{data["arriveTime"] ? getDisplayDatetime(data["arriveTime"]) : "-"}</td>
            </tr>
            {(isCollapse || data["eventType"] === MONITOR_STATUS.TOILET.USING) ? null :
                <tr>
                    <td></td>
                    <td colSpan="9">
                        <Table className="table-nowrap mb-0">
                            <thead className="table-light">
                                <tr>
                                    <th scope="col">{t('PageReport.Table.Change.StartDate')}</th>
                                    <th scope="col">{t('PageReport.Table.Change.StartTime')}</th>
                                    <th scope="col">{t('PageReport.Table.Change.Event')}</th>
                                    <th scope="col">{t('PageReport.Table.Change.Duration')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {data["changeID"].map((i) => {
                                    let change = changes[i];
                                    return (
                                        <tr key={change["startTime"]}>
                                            <td className="fw-medium">{getDisplayDate(change["startTime"])}</td>
                                            <td>{getDisplayTime(change["startTime"])}</td>
                                            <td>{getStatus(change["status"], t)}</td>
                                            <td>{change["endTime"] === 0?"-":getDisplayDatetimeFromTimestamp(change["endTime"] - change["startTime"])}</td>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </Table>
                    </td>
                </tr>}
        </Fragment>
    );
}

export default ReportRow;