import { convertLocationToDB } from "../common/utils";
import { APIClient } from "../helpers/api_helper";
import { GET_SUMMARY_BED_ARRIVAL, GET_SUMMARY_BED_FALL, GET_SUMMARY_TOILET_ARRIVAL, GET_SUMMARY_TOILET_FALL, GET_SUMMARY_TOILET_OT } from "../helpers/url_helper";

const api = new APIClient();

export const getSummaryToiletFallService = async (selectedMultiFloorPlan, dateRange) => {
    console.log("getSummaryToiletFall........................2 selectedMultiFloorPlan: " + selectedMultiFloorPlan + " | dateRange: " + dateRange);
    console.log(dateRange[0].toLocaleDateString());
    console.log(selectedMultiFloorPlan)
    let startDt = dateRange[0]>dateRange[1]?dateRange[1]:dateRange[0];
    startDt.setHours(0);
    startDt.setMinutes(0);
    startDt.setSeconds(0);
    let endDt = dateRange[0]>dateRange[1]?dateRange[0]:dateRange[1];
    endDt.setDate(endDt.getDate());
    endDt.setHours(23);
    endDt.setMinutes(59);
    endDt.setSeconds(59);
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return await api.get(GET_SUMMARY_TOILET_FALL, {location: JSON.stringify(convertLocationToDB(selectedMultiFloorPlan)), startDt: Math.trunc(startDt.getTime()/1000), endDt: Math.trunc(endDt.getTime()/1000), timezone: timezone});
};

export const getSummaryToiletOTService = async (selectedMultiFloorPlan, dateRange) => {
    console.log("getSummaryToiletOTService........................2 selectedMultiFloorPlan: " + selectedMultiFloorPlan + " | dateRange: " + dateRange);
    console.log(dateRange[0].toLocaleDateString());
    console.log(selectedMultiFloorPlan)
    let startDt = dateRange[0]>dateRange[1]?dateRange[1]:dateRange[0];
    startDt.setHours(0);
    startDt.setMinutes(0);
    startDt.setSeconds(0);
    let endDt = dateRange[0]>dateRange[1]?dateRange[0]:dateRange[1];
    endDt.setDate(endDt.getDate());
    endDt.setHours(23);
    endDt.setMinutes(59);
    endDt.setSeconds(59);
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return await api.get(GET_SUMMARY_TOILET_OT, {location: JSON.stringify(convertLocationToDB(selectedMultiFloorPlan)), startDt: Math.trunc(startDt.getTime()/1000), endDt: Math.trunc(endDt.getTime()/1000), timezone: timezone});
};

export const getSummaryToiletArrivalService = async (selectedMultiFloorPlan, dateRange) => {
    let startDt = dateRange[0]>dateRange[1]?dateRange[1]:dateRange[0];
    startDt.setHours(0);
    startDt.setMinutes(0);
    startDt.setSeconds(0);
    let endDt = dateRange[0]>dateRange[1]?dateRange[0]:dateRange[1];
    endDt.setDate(endDt.getDate());
    endDt.setHours(23);
    endDt.setMinutes(59);
    endDt.setSeconds(59);
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return await api.get(GET_SUMMARY_TOILET_ARRIVAL, {location: JSON.stringify(convertLocationToDB(selectedMultiFloorPlan)), startDt: Math.trunc(startDt.getTime()/1000), endDt: Math.trunc(endDt.getTime()/1000), timezone: timezone});
}

export const getSummaryBedFallService = async (selectedMultiFloorPlan, dateRange) => {
    let startDt = dateRange[0]>dateRange[1]?dateRange[1]:dateRange[0];
    startDt.setHours(0);
    startDt.setMinutes(0);
    startDt.setSeconds(0);
    let endDt = dateRange[0]>dateRange[1]?dateRange[0]:dateRange[1];
    endDt.setDate(endDt.getDate());
    endDt.setHours(23);
    endDt.setMinutes(59);
    endDt.setSeconds(59);
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return await api.get(GET_SUMMARY_BED_FALL, {location: JSON.stringify(convertLocationToDB(selectedMultiFloorPlan)), startDt: Math.trunc(startDt.getTime()/1000), endDt: Math.trunc(endDt.getTime()/1000), timezone: timezone});
};

export const getSummaryBedArrivalService = async (selectedMultiFloorPlan, dateRange) => {
    let startDt = dateRange[0]>dateRange[1]?dateRange[1]:dateRange[0];
    startDt.setHours(0);
    startDt.setMinutes(0);
    startDt.setSeconds(0);
    let endDt = dateRange[0]>dateRange[1]?dateRange[0]:dateRange[1];
    endDt.setDate(endDt.getDate());
    endDt.setHours(23);
    endDt.setMinutes(59);
    endDt.setSeconds(59);
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return await api.get(GET_SUMMARY_BED_ARRIVAL, {location: JSON.stringify(convertLocationToDB(selectedMultiFloorPlan)), startDt: Math.trunc(startDt.getTime()/1000), endDt: Math.trunc(endDt.getTime()/1000), timezone: timezone});
}