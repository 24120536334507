import React from 'react';
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";

const LineChart = ({ dataColors, xData, seriesData, xFont }) => {
    var linechartBasicColors = getChartColorsArray(dataColors);
    const series = seriesData;
    var options = {
        chart: {
            height: 350,
            type: 'line',
            zoom: {
                enabled: false
            },
            toolbar: {
                show: false
            }
        },
        markers: {
            size: 4,
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'straight'
        },
        colors: linechartBasicColors,
        xaxis: {
            categories: xData,
            labels: {
                rotate: -55,
                style: {
                  fontSize: xFont,
                },
            }
        },
        legend: {

            position: 'top',
        }
    };
    return (
        <React.Fragment>
            <ReactApexChart dir="ltr"
                options={options}
                series={series}
                type="line"
                height="350"
                className="apex-charts"
            />
        </React.Fragment>
    );
};

export default LineChart;