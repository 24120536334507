import React, { useEffect, useRef, useState } from 'react';

//import Scss
import './assets/scss/themes.scss';

//imoprt Route
import Route from './Routes';
import { setDeviceConnection, setDeviceStatus, setMQConnection, setPin, setSocketConnection, timerIncrement } from './slices/device/reducer';
import { addReportUpdate, setReportUpdate } from './slices/event/reducer';
import DataConnector from './helpers/DataConnector';
import { useDispatch, useSelector } from 'react-redux';
import Alarm_audio from './assets/sounds/alarm.mp3';
import AlarmConfirmModal from './Components/Common/AlarmConfirmModal';
import { getAllDevices } from './slices/thunks';

const onConnected = (dispatch) => {
  dispatch(setSocketConnection(true));
};
const onDisconnected = (dispatch) => {
  dispatch(setSocketConnection(false));
};
const onMQConnected = (dispatch) => {
  dispatch(setMQConnection(true));
};
const onMQDisconnected = (dispatch) => {
  dispatch(setMQConnection(false));
};
const onDeviceConnected = (dispatch, id, changeStatusTime) => {
  dispatch(setDeviceConnection({id: id, isConnected: true, changeStatusTime: changeStatusTime}))
};
const onDeviceDisconnected = (dispatch, id, changeStatusTime) => {
  dispatch(setDeviceConnection({id: id, isConnected: false, changeStatusTime: changeStatusTime}))
};
const onDeviceStatusChange = (dispatch, id, status, changeStatusTime, newEvent) => {
  dispatch(setDeviceStatus({id: id, status: status, changeStatusTime: changeStatusTime}));
  if (newEvent !== undefined && newEvent.pending.events.length > 0) {
    dispatch(addReportUpdate(newEvent));
  }
};
const onReportUpdate = (dispatch, id) => {
  dispatch(setReportUpdate(id));
};
const onPinUpdate = (dispatch, id, isPin) => {
  dispatch(setPin({id: id, isPin: isPin}));
}
const onForceReFresh = () => {
  window.location.reload();
};


function App() {
  console.log("Environment: " + process.env.NODE_ENV);
  console.log("Stage Environment: " + process.env.REACT_APP_STAGE);
  const intervalRef = useRef();
  const dispatch = useDispatch();
  const totalDevices = useSelector((state => state.Device.followUp.totalDevices));
  const isOnAlarm = useSelector((state => state.Device.followUp.isOnAlarm));
  const [audio, setAudio] = useState(new Audio(Alarm_audio));
  const isUserLogout = useSelector((state => state.Profile?.user?.id === undefined));
  const [isShowAlarmConfirmModal, setShowAlarmConfirmModal] = useState(false);

  const setup = () => {
    // start timer 
    intervalRef.current = setInterval(() => {
      dispatch(timerIncrement())
    }, 1000);
    
    dispatch(getAllDevices());

    // start socket
    const dataConnector = DataConnector.getInstance();
    dataConnector.createSocket();
    dataConnector.setCBs(
      () => {onConnected(dispatch)}, 
      () => {onDisconnected(dispatch)}, 
      () => {onMQConnected(dispatch)}, 
      () => {onMQDisconnected(dispatch)}, 
      (data) => {onDeviceConnected(dispatch, data.id, data.changeStatusTime)}, 
      (data) => {onDeviceDisconnected(dispatch, data.id, data.changeStatusTime)}, 
      (data) => {onDeviceStatusChange(dispatch, data.id, data.status, data.changeStatusTime, data.newEvent)}, 
      (id) => {onReportUpdate(dispatch, id)}, 
      (data) => {onPinUpdate(dispatch, data.id, data.isPin)}, 
      onForceReFresh);
    dataConnector.setup();

    window.addEventListener('beforeunload', clearIntervalAndSaveData);
    return () => {
      clearIntervalAndSaveData();
      dataConnector.closeSocket(); 
      if (audio !== undefined) {
        audio.pause();
        audio.currentTime = 0;
      }    
    };
  }
  useEffect(() => {
    if (!isUserLogout && (intervalRef.current === null || intervalRef.current !== undefined)) {
      return setup();
    }
  },[]);

  const clearIntervalAndSaveData = () => {
    // Clear the interval and save any necessary data
    clearInterval(intervalRef.current);
    intervalRef.current = null;
  };

  useEffect(() => {
    if (isUserLogout) {
      clearIntervalAndSaveData();
      const dataConnector = DataConnector.getInstance();
      dataConnector.closeSocket(); 
      if (audio !== undefined) {
        audio.pause();
        audio.currentTime = 0;
      }    
    }
    else {
      setShowAlarmConfirmModal(true);
      if (intervalRef.current === null || intervalRef.current === undefined) {
        return setup();
      }
    }

  }, [isUserLogout]);

  useEffect(()=>{
    if (audio !== undefined) {
      if ((totalDevices.BED > 0 || totalDevices.TOILET> 0 ) && isOnAlarm) {
          console.log("play sound");      
          audio.loop = true;      
          audio.play();
      }
      else {
          console.log("pause sound");            
          audio.pause();
          audio.currentTime = 0;
      }
    }
  }, [totalDevices, isOnAlarm]);

  return (
    <React.Fragment>
      <AlarmConfirmModal show={isShowAlarmConfirmModal} closeModal={()=>{setShowAlarmConfirmModal(false)}}/>
      <Route />
    </React.Fragment>
  );
}

export default App;
