import React from 'react';
import ReactApexChart from "react-apexcharts";

import getChartColorsArray from "../../Components/Common/ChartsDynamicColor";

const ColumnChart = ({ dataColors, yName, xData, yData, xFont }) => {
    var chartColumnColors = getChartColorsArray(dataColors);
    const series = [
        {
            name: yName,
            data: yData,
        },
    ];

    var options = {
        chart: {
            height: 350,
            type: 'bar',
            toolbar: {
                show: false,
            }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '45%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        colors: chartColumnColors,
        xaxis: {
            categories: xData,
            labels: {
                rotate: -55,
                style: {
                  fontSize: xFont,
                },
            }
        },
        yaxis: {
            title: {
                text: ''
            }
        },
        grid: {
            borderColor: '#f1f1f1',
        },
        fill: {
            opacity: 1

        },
        tooltip: {
            y: {
                formatter: function (val) {
                    return val;
                }
            }
        }
    };


    return (
        <ReactApexChart dir="ltr" className="apex-charts"
            series={series}
            options={options}
            type="bar"
            height={350}
        />
    );
};

export default ColumnChart;