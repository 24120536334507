import React, { Fragment } from "react";
import { Col, Container, Row } from "reactstrap";
import DeviceSection from "./DeviceSection";
import { useTranslation } from 'react-i18next';
import FollowUpSection from "./FollowUpSection";
import { useStore } from "react-redux";
import HistorySection from "./HistorySection";
import { DISPLAY_MODE } from "../../common/constants";

function Dashboard() {
  const { t } = useTranslation();
  document.title = t('PageMain.DocumentTitle');
  const user = useStore().getState().Profile.user;

  let minWidth = window.innerWidth-30;
  let minWidth2 = (minWidth-20)/2;
  return (
    <React.Fragment>
      <div className="page-content page-content-dash">
        <Container fluid className="dash">
          {user.companyDisplayMode === DISPLAY_MODE.COLUMN_2?
          <Fragment>
          <Row className="col_2_device">
            <Col className="dash-section" style={{minWidth:minWidth}} xs={12}>
              <DeviceSection />
            </Col>
          </Row>
          <Row className="col_2_event" style={{minWidth:minWidth}}>
            <Col className="dash-section" style={{minWidth:minWidth2}} xs={12} sm={12} md={6}>
              <FollowUpSection />
            </Col>
            <Col className="dash-section" style={{minWidth:minWidth2}} xs={12} sm={12} md={6}>
              <HistorySection />
            </Col>
          </Row></Fragment>:
          <Row className="col_3_device">
          <Col className="dash-section" style={{minWidth:minWidth}} sm={12} xl={6}>
            <DeviceSection />
          </Col>
          <Col className="dash-section" style={{minWidth:minWidth2}} sm={6} xl={3}>
            <FollowUpSection />
          </Col>
          <Col className="dash-section" style={{minWidth:minWidth2}} sm={6} xl={3}>
            <HistorySection />
          </Col>
          </Row>}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
