const PRIVILEGE_NAME = {
    DEVICE: "DEVICE",
    EVENT: "EVENT",
    REPORT: "REPORT",
    SETTING: "SETTING",
    SUMMARY: "SUMMARY",
    USER: "USER",
    DEVELOPER: "DEVELOPER"
};

export {
    PRIVILEGE_NAME
}