import { convertArrayToString, convertLocationToDB, convertMonitorStatusToDB, convertMonitorTypeToDB } from "../common/utils";
import { APIClient } from "../helpers/api_helper";
import { CREATE_EVENT_MESSAGE, DELETE_EVENT_REPORT, GET_ALL_PENDING_EVENT, GET_EVENT_MESSAGE, GET_EVENT_REPORT, GET_EVENT_REPORT_EXPORT, GET_EVENT_REPORT_LIST, POST_EVENT_REPORT_SAVE, POST_EVENT_REPORT_SAVE_AS, REPORT_EVENT } from "../helpers/url_helper";
import { saveAs } from 'file-saver';

const api = new APIClient();

export const getAllPendingEventsService = async () => {
    return await api.get(GET_ALL_PENDING_EVENT);
};

export const reportEventService = async (eventID, isFalseAlarm) => {
    return await api.create(REPORT_EVENT, {id: eventID, isFalseAlarm: isFalseAlarm});
};

export const createEventMessageService = async (eventID, message) => {
    return await api.create(CREATE_EVENT_MESSAGE, {id: eventID, message: message});
};

export const getEventMessagesService = async (eventID) => {
    return await api.get(GET_EVENT_MESSAGE+"/"+eventID);
};

export const getEventReportService = async (selectedMultiFloorPlan, selectedType, selectedStatus, dateRange, pageNumber, pageSize) => {
    let data2 = dateRange[1]
    if (isNaN(data2)) {
        data2 = dateRange[0];
    }
    let startDt = new Date(dateRange[0]>data2?data2:dateRange[0]);
    startDt.setHours(0);
    startDt.setMinutes(0);
    startDt.setSeconds(0);
    let endDt = new Date(dateRange[0]>data2?dateRange[0]:data2);
    endDt.setDate(endDt.getDate());
    endDt.setHours(23);
    endDt.setMinutes(59);
    endDt.setSeconds(59);
    return await api.get(GET_EVENT_REPORT, {page: pageNumber, pageSize: pageSize, location: JSON.stringify(convertLocationToDB(selectedMultiFloorPlan)), type: JSON.stringify(convertMonitorTypeToDB(selectedType)), status: JSON.stringify(convertMonitorStatusToDB(selectedStatus)), startDt: Math.trunc(startDt.getTime()/1000), endDt: Math.trunc(endDt.getTime()/1000)});
};

export const saveEventReportService = async (reportID, selectedMultiFloorPlan, selectedType, selectedStatus, dateRange, reportName) => {
    let data2 = dateRange[1]
    if (isNaN(data2)) {
        data2 = dateRange[0];
    }
    let startDt = new Date(dateRange[0]>data2?data2:dateRange[0]);
    startDt.setHours(0);
    startDt.setMinutes(0);
    startDt.setSeconds(0);
    let endDt = new Date(dateRange[0]>data2?dateRange[0]:data2);
    endDt.setDate(endDt.getDate());
    endDt.setHours(23);
    endDt.setMinutes(59);
    endDt.setSeconds(59);
    let data = {
        location: JSON.stringify(convertLocationToDB(selectedMultiFloorPlan)), 
        type: JSON.stringify(convertMonitorTypeToDB(selectedType)), 
        status: JSON.stringify(convertMonitorStatusToDB(selectedStatus)), 
        "startDt": Math.trunc(startDt.getTime()/1000), 
        "endDt": Math.trunc(endDt.getTime()/1000), 
        "name": reportName,
        "reportID": reportID};
    let result = await api.create(POST_EVENT_REPORT_SAVE, data);
    return result;
};

export const exportEventReportService = async (selectedMultiFloorPlan, selectedType, selectedStatus, dateRange, language, fileName) => {
    let data2 = dateRange[1]
    if (isNaN(data2)) {
        data2 = dateRange[0];
    }
    let startDt = new Date(dateRange[0]>data2?data2:dateRange[0]);
    startDt.setHours(0);
    startDt.setMinutes(0);
    startDt.setSeconds(0);
    let endDt = new Date(dateRange[0]>data2?dateRange[0]:data2);
    endDt.setDate(endDt.getDate());
    endDt.setHours(23);
    endDt.setMinutes(59);
    endDt.setSeconds(59);
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let response = await api.get(GET_EVENT_REPORT_EXPORT, {responseType: 'blob', params: {location: JSON.stringify(convertLocationToDB(selectedMultiFloorPlan)), type: JSON.stringify(convertMonitorTypeToDB(selectedType)), status: JSON.stringify(convertMonitorStatusToDB(selectedStatus)), startDt: Math.trunc(startDt.getTime()/1000), endDt: Math.trunc(endDt.getTime()/1000), locationString: JSON.stringify(convertArrayToString(selectedMultiFloorPlan)), typeString: JSON.stringify(convertArrayToString(selectedType)), statusString: JSON.stringify(convertArrayToString(selectedStatus)), dateRangeString: startDt+ " - " + endDt, language: language, fileName: fileName, timezone: timezone}});

    const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  
    saveAs(blob, fileName+'.xlsx');
};


export const getSavedReportListService = async (searchText, sortColumn, sortDirection, pageNumber, pageSize) => {
    console.log("getSavedReportListService pageNumber: " + pageNumber);  
    let result = await api.get(GET_EVENT_REPORT_LIST, {search: searchText, sortColumn: sortColumn, sortDirection: sortDirection, page: pageNumber, pageSize: pageSize});
    console.log("result");
    console.log(result);
    return result;
};

export const deleteSavedReportService = async (reportID) => {
    let result = await api.delete(DELETE_EVENT_REPORT+"/"+reportID);
    console.log("result");
    console.log(result);
    return result;
};

export const getEventReportByReportIDService = async (reportID) => {
    return await api.get(GET_EVENT_REPORT+"/"+reportID);
};

export const exportEventReportByReportIDService = async (reportID, language, fileName) => {
    console.log(GET_EVENT_REPORT_EXPORT+"/"+reportID);
    let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let response = await api.get(GET_EVENT_REPORT_EXPORT+"/"+reportID, {responseType: 'blob', params: {language: language, fileName: fileName, timezone: timezone}});

    const blob = new Blob([response], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  
    saveAs(blob, fileName+'.xlsx');
};

