import { createSlice } from "@reduxjs/toolkit";
import { ALL_FLOORS, ALL_LOCATIONS, ALL_MONITOR_STATUS, ALL_MONITOR_TYPE_STATUS, ALL_MONITOR_TYPES } from "../../Components/Common/DeviceFilter";
import { MONITOR_TYPE } from "../../common/constants";
import { isGoFollowUp } from "../../helpers/display_help";
import Alarm_audio from '../../assets/sounds/alarm.mp3';

export const initialState = {
  normal: {
    totalDevices: {
      BED: 0,
      TOILET: 0,
    },
    devices: []
  },
  followUp: {
    totalDevices: {
      BED: 0,
      TOILET: 0,
    },
    devices: [],
    isOnAlarm: false,
  },
  devices: {},
  timer: {},
  error: {},
  isMQConnected: false,
  isSocketConnected: false
};

const deviceSlice = createSlice({
  name: 'Device',
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
    },
    getDeviceSuccess(state, action) {
      state.normal = action.payload.normal;
      state.followUp = {
        ...state.followUp,
        ...action.payload.followUp,
      };
      state.devices = action.payload.devices;
      state.timer = action.payload.timer;
    },
    timerIncrement: (state, action) => {
      let newTimer = {}
      for (let t in state.timer) {
        if (state.timer[t] !== null) {
          newTimer[t] = state.timer[t] + 1000;
        }
      }
      return {
        ...state,
        timer: newTimer
      }
    },
    filterDevices: (state, action) => {
      console.log(action);
      let floor = [];
      let location = [];
      let isCheckLocation = true;
      let type = [];
      let statusMonitorType = [];
      let status = [];
      let isCheckStatus = true;
      if (action.payload.floor.length > 0) {
        action.payload.floor.forEach((f) => {
          if (f.value === ALL_FLOORS) {
            floor = null;
            isCheckLocation = false;
          }
          else if (f.value.includes(ALL_LOCATIONS)) {
            floor.push(f.floor);
            isCheckLocation = false;
          }
          else {
            location.push(f.value);
          }
        })
        
        if (floor !== null && floor.length === 0) {
          floor = null;
        }
      } 
    
      if (!isCheckLocation && location.length === 0) {
        location = null;
      }

      action.payload.monitorType.forEach((t) => {
        if (t.value === ALL_MONITOR_TYPES) {
          type = null;
        }
        else {
          type.push(t.value);
        }
      });

      if (action.payload.status.length > 0) {
        action.payload.status.forEach((s) => {
          if (s.value === ALL_MONITOR_STATUS) {
            statusMonitorType = null;
            isCheckStatus = false;
          }
          else if (s.value.includes(ALL_MONITOR_TYPE_STATUS)) {
            statusMonitorType.push(s.status);
            isCheckStatus = false;
          }
          else {
            status.push(s.value);
          }
        });

        if (statusMonitorType !== null && statusMonitorType.length === 0) {
          statusMonitorType = null;
        }
      }

      if (!isCheckStatus && status.length === 0) {
        status = null;
      }

      state.normal.devices.forEach((id) => {
        let item = state.devices[id];
        item.isShow = true;

        let shouldShow = false;
        if (floor !== null) {
          if (floor.length > 0) {
            if (floor.includes(item.floorID.toString())) {
              shouldShow = true;
            }
          }
        }
        else if (location === null){
          shouldShow = true;
        }

        if (!shouldShow) {
          if (location !== null) {
            if (location.length > 0) {
              if (location.includes(item.locationID.toString())) {
                shouldShow = true;
              }
            }           
          }
        }
        item.isShow = shouldShow;

        if (item.isShow) {
          if (type !== null) {
            if (type.length > 0) {
              if (!type.includes(item.monitorTypeID.toString())) {
                item.isShow = false;
              }
            }
            else if (type.length === 0) {
              item.isShow = false;
            }
          }

          if (item.isShow) {
            let shouldShow = false;
            if (statusMonitorType !== null) {
              if (statusMonitorType.length > 0) {
                if (statusMonitorType.includes(item.monitorTypeID.toString())) {
                  shouldShow = true;
                }
              }
            }
            else if (status === null) {
              shouldShow = true;
            }

            if (!shouldShow) {
              if (status !== null) {
                if (status.length > 0) {
                  if (status.includes(item.currentMonitorStatus)) {
                    shouldShow = true;
                  }
                }
              }
            }

            item.isShow = shouldShow;
          }  
        }
      })
    },
    setMQConnection: (state, action) => {
      state.isMQConnected = action.payload;
    },
    setSocketConnection: (state, action) => {
      state.isSocketConnected = action.payload;
    },
    setDeviceConnection: (state, action) => {
      let device = {...state.devices[action.payload.id]};
      if (device !== undefined && device.isConnected !== action.payload.isConnected) {
        device["isConnected"] = action.payload.isConnected;
        device["statusStarttime"] = action.payload.changeStatusTime;
        let time = new Date() - new Date(action.payload.changeStatusTime);

        let normalToilet = state.normal.totalDevices.TOILET;
        let normalBed = state.normal.totalDevices.BED;
        let normalDevice = [...state.normal.devices];
        let followUpToilet = state.followUp.totalDevices.TOILET;
        let followUpBed = state.followUp.totalDevices.BED;
        let followUpDevice = [...state.followUp.devices];
        if (action.payload.isConnected) {          
          if (!isGoFollowUp(device.currentMonitorStatus, device)) {
            if (device.monitorTypeID === MONITOR_TYPE.TOILET) {
              followUpToilet = followUpToilet - 1;
              normalToilet = normalToilet + 1;
              let index = followUpDevice.indexOf(action.payload.id);
              if (index !== -1) {
                followUpDevice.splice(index, 1);
                normalDevice.push(action.payload.id);
              }
            }
            else if (device.monitorTypeID === MONITOR_TYPE.BED) {
              followUpBed = followUpBed - 1;
              normalBed = normalBed + 1;
              let index = followUpDevice.indexOf(action.payload.id);
              if (index !== -1) {
                followUpDevice.splice(index, 1);
                normalDevice.push(action.payload.id);
              }
            }
          }
        }
        else {
          if (device.monitorTypeID === MONITOR_TYPE.TOILET) {
            followUpToilet = followUpToilet + 1;
            normalToilet = normalToilet - 1;
            let index = normalDevice.indexOf(action.payload.id);
            if (index !== -1) {
              normalDevice.splice(index, 1);
              followUpDevice.unshift(action.payload.id);
            }
          }
          else if (device.monitorTypeID === MONITOR_TYPE.BED) {
            followUpBed = followUpBed + 1;
            normalBed = normalBed - 1;
            let index = normalDevice.indexOf(action.payload.id);
            if (index !== -1) {
              normalDevice.splice(index, 1);
              followUpDevice.unshift(action.payload.id);
            }
          }
        }

        return {
          ...state,
          devices: {...state.devices, [action.payload.id]: device},
          timer: {
            ...state.timer,
            [action.payload.id]: time
          },
          normal: {
            totalDevices: {
              BED: normalBed,
              TOILET: normalToilet,
            },
            devices: normalDevice
          },
          followUp: {
            ...state.followUp,
            totalDevices: {
              BED: followUpBed,
              TOILET: followUpToilet,
            },
            devices: followUpDevice
          },
        };
      }
      return state;
    },
    setDeviceStatus: (state, action) => {
      let device = {...state.devices[action.payload.id]};
      if (device !== undefined) {
        let oldStatus = device.currentMonitorStatus;
        let normalToilet = state.normal.totalDevices.TOILET;
        let normalBed = state.normal.totalDevices.BED;
        let normalDevice = [...state.normal.devices];
        let followUpToilet = state.followUp.totalDevices.TOILET;
        let followUpBed = state.followUp.totalDevices.BED;
        let followUpDevice = [...state.followUp.devices];
        device["currentMonitorStatus"] = action.payload.status;
        device["statusStarttime"] = action.payload.changeStatusTime;
        if (isGoFollowUp(action.payload.status, device)) {
          if (!isGoFollowUp(oldStatus, device)) {
              if (device.monitorTypeID === MONITOR_TYPE.TOILET) {
                followUpToilet = followUpToilet + 1;
                normalToilet = normalToilet - 1;
                let index = normalDevice.indexOf(action.payload.id);
                if (index !== -1) {
                  normalDevice.splice(index, 1);
                  followUpDevice.unshift(action.payload.id);
                }
              }
              else if (device.monitorTypeID === MONITOR_TYPE.BED) {
                followUpBed = followUpBed + 1;
                normalBed = normalBed - 1;
                let index = normalDevice.indexOf(action.payload.id);
                if (index !== -1) {
                  normalDevice.splice(index, 1);
                  followUpDevice.unshift(action.payload.id);
                }
              }
          }
        }
        else {
          if (isGoFollowUp(oldStatus, device)) {
            if (device.monitorTypeID === MONITOR_TYPE.TOILET) {
              followUpToilet = followUpToilet - 1;
              normalToilet = normalToilet + 1;
              let index = followUpDevice.indexOf(action.payload.id);
              if (index !== -1) {
                followUpDevice.splice(index, 1);
                normalDevice.push(action.payload.id);
              }
            }
            else if (device.monitorTypeID === MONITOR_TYPE.BED) {
              followUpBed = followUpBed - 1;
              normalBed = normalBed + 1;
              let index = followUpDevice.indexOf(action.payload.id);
              if (index !== -1) {
                followUpDevice.splice(index, 1);
                normalDevice.push(action.payload.id);
              }
            }
          }
        }
        let time = new Date() - new Date(action.payload.changeStatusTime);
        return {
          ...state,
          normal: {
            totalDevices: {
              BED: normalBed,
              TOILET: normalToilet,
            },
            devices: normalDevice
          },
          followUp: {
            ...state.followUp,
            totalDevices: {
              BED: followUpBed,
              TOILET: followUpToilet,
            },
            devices: followUpDevice
          },
          devices: {...state.devices, [action.payload.id]: device},
          timer: {
            ...state.timer,
            [action.payload.id]: time
          }
        };
      }
      return state;
    },
    setPin: (state, action) => {
      let device = {...state.devices[action.payload.id]};
      if (device !== undefined) {
        device["pin"] = action.payload.isPin;
        let normalDevice = [];
        if (action.payload.isPin === true) {
          normalDevice.push(action.payload.id);
          state.normal.devices.forEach(i => {
            if (i !== action.payload.id) {
              normalDevice.push(i);
            }
          })
        }
        else {
          state.normal.devices.forEach(i => {
            if (i !== action.payload.id) {
              normalDevice.push(i);
            }
          })
          normalDevice.push(action.payload.id);
        }
        return {
          ...state,
          normal: {
            ...state.normal,
            devices: normalDevice
          },
          devices: {...state.devices, [action.payload.id]: device}
        };
      }
      return state;
    },
    setOnAlarm: (state, action) => {
      return {
        ...state,
        followUp: {
          ...state.followUp,
          isOnAlarm: action.payload
        }
      }
    },
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
  }
});

export const {
  apiError,
  getDeviceSuccess,
  timerIncrement,
  filterDevices,
  setMQConnection,
  setSocketConnection,
  setDeviceConnection,
  setDeviceStatus,
  setPin,
  setOnAlarm,
  resetState
} = deviceSlice.actions

export default deviceSlice.reducer;