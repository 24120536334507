import { createSlice } from "@reduxjs/toolkit";

export const initialState = {
  user: "",
  error: "",
  loading: false,
  isUserLogout: true,
  errorFlag: false,
};

const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
      state.loading = true;
      state.isUserLogout = true;
      state.errorFlag = true;

    },
    loginSuccess(state, action) {
      //state.user = action.payload
      state.loading = false;
      state.errorFlag = false;
      state.isUserLogout = false;

    },
    logoutUserSuccess(state, action) {
      state.isUserLogout = true
    },
    reset_login_flag(state) {
      state.error = null
      state.loading = false;
      state.errorFlag = false;
      state.isUserLogout = true;
    },
    resetState: (state) => {return {...initialState, isUserLogout: true}},
  },
});

export const {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  reset_login_flag,
  resetState
} = loginSlice.actions

export default loginSlice.reducer;