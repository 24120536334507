import React  from "react";
import { MONITOR_STATUS, MONITOR_TYPE } from "../../common/constants";
import { useSelector } from "react-redux";
import { getDisplayDatetimeFromTimestamp } from "../../helpers/display_help";


const DeviceTimer = ({data}) => {
    const timer = useSelector((state => state.Device.timer[data.id]));
    const timerString = timer !== undefined?getDisplayDatetimeFromTimestamp(timer):"-";

    if (!data.isConnected) {
        return <div className="dash-device-card-text-nosignal">{timerString}</div>
    }
    else {
        if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
            if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.TOILET.IDLE) {
                return <div className="dash-device-card-text-toilet-idle">{timerString}</div>;
            }
            else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING) {
                return <div className="dash-device-card-text-toilet-using">{timerString}</div>;
            }    
            else {
                return <div className="dash-device-card-text-toilet-idle">-</div>
            }        
        }
        else {
            if (data.currentMonitorStatus === null || data.currentMonitorStatus === MONITOR_STATUS.BED.IDLE) {
                return <div className="dash-device-card-text-toilet-idle">{timerString}</div>;
            }
            else {
                return <div className="dash-device-card-text-toilet-idle">-</div>
            }      
        }
    }
}

export default DeviceTimer;