import { Card, CardBody } from "reactstrap";
import DashboardSectionHeader from "./DashboardSectionHeader";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import FollowUpCard from "./FollowUpCard";
import { AlarmOffIcon, AlarmOnIcon } from "../../Components/Common/SVG";
import colors from "../../assets/scss/config/saas/_colors.scss";
import { useEffect, useRef, useState } from "react";
import { setOnAlarm } from "../../slices/device/reducer";

function FollowUpSection() {    
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const totalDevices = useSelector((state => state.Device.followUp.totalDevices));
    const devices = useSelector((state => state.Device.followUp.devices));
    const isOnAlarm = useSelector((state => state.Device.followUp.isOnAlarm));
    return (
        <Card className="section-card">
            <CardBody className="section-cardbody">
                <div className="d-flex flex-column section-container">
                    <div className="d-flex flex-row justify-content-between">
                        <div className="d-flex flex-row">
                            <DashboardSectionHeader title={t('PageMain.FollowUp.Title')} bed={totalDevices.BED + t('Common.Space1') + (totalDevices.BED > 1 ? t('Common.Beds') : t('Common.Bed'))} toilet={totalDevices.TOILET + t('Common.Space1') + (totalDevices.TOILET > 1 ? t('Common.Toilets') : t('Common.Toilet'))} />
                            {/*<i className="bx bx-dots-horizontal-rounded dash-device-header-icon p-2"></i>*/}
                            <div className="dash-alarm-container" onClick={()=>{dispatch(setOnAlarm(!isOnAlarm))}}>
                                {isOnAlarm?
                                <AlarmOnIcon scale={1.3} fill={colors.eventAlarmOn}/>:
                                <AlarmOffIcon scale={1.3} fill={colors.eventAlarmOff}/>}
                            </div>
                        </div>
                    </div>  
                    <div className="card-container">
                        {devices.map((data) => {
                            return (
                                <FollowUpCard key={data} data={data}/>
                            );
                        })}       
                    </div>           
                </div>
                    
            </CardBody>
        </Card>
    );
}

export default FollowUpSection;