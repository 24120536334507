import { APIClient } from "../../helpers/api_helper";
import { getAppliedRulesData } from "../../helpers/data_conversion_helper";
import { GET_APPLIED_RULES, GET_CARE_REQUIREMENT, GET_FLOORPLAN, GET_MONITOR_PERIOD, GET_MONITOR_STATUS, GET_MONITOR_TYPE, GET_REPORT_MONITOR_STATUS } from "../../helpers/url_helper";
import { apiError, getCareTypeSuccess, getFloorPlanSuccess, getMonitorPeriodSuccess, getMonitorStatusSuccess, getMonitorTypeSuccess, getReportMonitorStatusSuccess } from "./reducer";

const api = new APIClient();

export const getStaticFloorPlan = () => async (dispatch) => {
  try {    
    var data = await api.get(GET_FLOORPLAN);
    if (data) {      
      dispatch(getFloorPlanSuccess(data));
    }
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
};

export const getStaticMonitorType = () => async (dispatch) => {
  try {    
    var data = await api.get(GET_MONITOR_TYPE);
    if (data) {      
      dispatch(getMonitorTypeSuccess(data));
    }
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
};

export const getStaticMonitorStatus = () => async (dispatch) => {
  try {    
    var data = await api.get(GET_MONITOR_STATUS);
    if (data) {
      dispatch(getMonitorStatusSuccess(data));
    }
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
};

export const getStaticCareType = () => async (dispatch) => {
  try {    
    var data = await api.get(GET_CARE_REQUIREMENT);
    if (data) {      
      dispatch(getCareTypeSuccess(data));
    }
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
}

export const getStaticMonitorPeriod = () => async (dispatch) => {
  try {    
    var data = await api.get(GET_MONITOR_PERIOD);
    if (data) {      
      dispatch(getMonitorPeriodSuccess(data));
    }
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
}

export const getStaticAppliedRules = () => async (dispatch) => {
  try {    
    var data = await api.get(GET_APPLIED_RULES);
    if (data) {      
      dispatch(getAppliedRulesData(data));
    }
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
}

export const getStaticReportMonitorStatus = () => async (dispatch) => {
  try {    
    var data = await api.get(GET_REPORT_MONITOR_STATUS);
    if (data) {
      dispatch(getReportMonitorStatusSuccess(data));
    }
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
};