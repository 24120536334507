import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { setOnAlarm } from "../../slices/device/reducer";

const AlarmConfirmModal = ({show, closeModal}) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    return (
        <Modal isOpen={show} toggle={()=>{closeModal()}} centered={true}>
            <div className="modal-header d-flex flex-row align-items-center justify-content-center">
                <div className="modal-title" id="varyingcontentModalLabel">{t('AlarmConfirmModal.Title')}</div>
            </div>
            <ModalBody className="d-flex flex-row align-items-center justify-content-center">               
                <Button className="btn-sm alarm-confirm-modal-btn" color="primary" onClick={() => { 
                    dispatch(setOnAlarm(false))
                    closeModal()
                }}> {t('AlarmConfirmModal.BtnTurnOff')} </Button>
                <Button className="btn-sm alarm-confirm-modal-btn" color="primary" onClick={() => { 
                    dispatch(setOnAlarm(true))
                    closeModal()
                }}> {t('AlarmConfirmModal.BtnTurnOn')} </Button>   
            </ModalBody>
        </Modal>
    );
}

export default AlarmConfirmModal;