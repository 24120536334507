import { useState } from "react";
import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import { saveEventReport } from "../../slices/event/thunk";
import { useDispatch, useSelector } from "react-redux";
import { setSaveReportName } from "../../slices/event/reducer";

const ReportSaveModal = ({show, onCloseClick, dateRange, selectedMultiFloorPlan, selectedMultiMonitorType, selectedMultiMonitorStatus }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedReportID = useSelector(state => state.Event.report.reportID);
    const reportName = useSelector(state => state.Event.report.reportName);
    const [isShowError, setShowError] = useState(false);

    const closeModal = (isRedirect) => {
        setShowError(false);
        onCloseClick(isRedirect);
    }

    return (
        <Modal isOpen={show} toggle={()=>{closeModal(false)}} centered={true}>
            <div className="modal-header">
                <div className="modal-title" id="varyingcontentModalLabel">{t('PageReport.SaveReportModal.Title')}</div>
                <div className="d-flex flex-row modal-close-btn">
                    <Button type="button" className="btn-close btn-sm btn-light" onClick={() => { closeModal(false); }} ></Button>
                </div>
            </div>
            <ModalBody className="d-flex flex-column">
                <form>
                    <div className="row">
                        <div className="col-md-3">
                            <label className="report-save-modal-label col-form-label" htmlFor="report-name">{t('PageReport.SaveReportModal.Name')}</label>
                        </div>
                        <div className="col-md-9">
                            <input type="text" className="form-control" id="report-name" value={reportName} onChange={(event)=>{dispatch(setSaveReportName(event.target.value))}} />
                        </div>
                    </div>
                </form>                
                <div className="d-flex flex-column align-items-center report-save-modal-btn-container">                    
                    {isShowError?<div className="report-save-modal-error">{t('PageReport.SaveReportModal.Error')}</div>:null}
                    <div className="d-flex flex-row justify-content-center ">    
                    <Button className="btn-sm report-save-modal-btn" color="primary" onClick={() => {
                        closeModal(false)
                    }}> {t('PageReport.SaveReportModal.BtnCancel')} </Button>     
                    {selectedReportID!==null?
                    <Button className="btn-sm report-save-modal-btn" color="primary" onClick={() => { 
                        if (reportName !== "") {
                            dispatch(saveEventReport("", selectedMultiFloorPlan, selectedMultiMonitorType, selectedMultiMonitorStatus, dateRange, reportName)).then(data=>{
                                if (data.data === false) {
                                    setShowError(true);
                                }
                                else {
                                    closeModal(true)
                                }
                            }
                        )}
                    }}> {t('PageReport.SaveReportModal.BtnSaveAsNew')} </Button>:null}
                    <Button className="btn-sm report-save-modal-btn" color="primary" onClick={() => { 
                        if (reportName !== "") {
                            dispatch(saveEventReport(selectedReportID!==null?selectedReportID.id:"", selectedMultiFloorPlan, selectedMultiMonitorType, selectedMultiMonitorStatus, dateRange, reportName)).then(data=>{
                                if (data.data === false) {
                                    setShowError(true);
                                }
                                else {
                                    closeModal(true)
                                }
                            }
                        )}
                    }}> {t('PageReport.SaveReportModal.BtnSave')} </Button>           
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ReportSaveModal;