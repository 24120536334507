import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { PRIVILEGE_NAME } from "../Components/constants/privilege";

const Navdata = () => {
    const history = useNavigate();
    const { t } = useTranslation();    
    const { privileges } = useSelector(state => ({
        privileges: state.Profile.user.privileges,
    }));

    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isSummary, setIsSummary] = useState(false);
    const [isReport, setIsReport] = useState(false);
    const [isDeveloper, setIsDeveloper] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'Summary') {
            setIsSummary(false);
        }
        if (iscurrentState !== 'Report') {
            setIsReport(false);
        }
        if (iscurrentState !== 'Report') {
            setIsReport(false);
        }
        if (iscurrentState !== 'Developer') {
            setIsDeveloper(false);
        }
    }, [
        history,
        iscurrentState,
        isDashboard,
        isSummary,
        isReport,
        isDeveloper,
    ]);

    const menuItems = [
        {
            label: t('MenuName.Name'),
            isHeader: true,
        },
        {
            id: "dashboard",
            label: t('MenuName.Dashboard'),
            icon: "bx bxs-dashboard nav-icon",
            link: "/dashboard",
            stateVariables: isDashboard,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            }
        }
    ];

    if (privileges !== undefined && privileges.hasOwnProperty(PRIVILEGE_NAME.SUMMARY)) {
        menuItems.push(
        {
            id: "summary",
            label: t('MenuName.Summary'),
            icon: "bx bx-doughnut-chart nav-icon",
            link: "/summary",
            click: function (e) {
                e.preventDefault();
                setIsSummary(!isSummary);
                setIscurrentState('Summary');
                updateIconSidebar(e);
            },
            stateVariables: isSummary
        })
    }
    if (privileges !== undefined && privileges?.hasOwnProperty(PRIVILEGE_NAME.REPORT)) {
        menuItems.push(
        {
            id: "report",
            label: t('MenuName.Report'),
            icon: "bx bx-file nav-icon",
            link: "/report",
            click: function (e) {
                e.preventDefault();
                setIsReport(!isReport);
                setIscurrentState('Report');
                updateIconSidebar(e);
            },
            stateVariables: isReport
        })
    }
    if (privileges !== undefined && privileges?.hasOwnProperty(PRIVILEGE_NAME.DEVELOPER)) {
        menuItems.push(
        {
            id: "developer",
            label: t('MenuName.Developer'),
            icon: "bx bx-file nav-icon",
            link: "/developer",
            click: function (e) {
                e.preventDefault();
                setIsReport(!isReport);
                setIscurrentState('Report');
                updateIconSidebar(e);
            },
            stateVariables: isReport
        })
    }
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;