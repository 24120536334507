import { Button } from "reactstrap";
import { useTranslation } from "react-i18next";
import { getDisplayDatetime } from "../../helpers/display_help";
import { Fragment } from "react";

function ReportListRow(props) {
    const { t } = useTranslation();
    const data = props.data;

    return (
        <Fragment>
            <tr>
                <td>{data["name"]}</td>
                <td>{getDisplayDatetime(data["created_time"])}</td>
                <td>
                    <Button className="btn-sm btn-light report-save-table-btn" onClick={props.onDelete}>{t('PageReport.SavedReportTable.BtnDelete')}</Button>
                    <Button color="primary" className="btn-sm report-save-table-btn" onClick={props.onView}>{t('PageReport.SavedReportTable.BtnView')}</Button>
                    <Button color="secondary" className="btn-sm report-save-table-btn" onClick={props.onExport}>{t('PageReport.SavedReportTable.BtnExport')}</Button>
                </td>
            </tr>
        </Fragment>
    );
}

export default ReportListRow;