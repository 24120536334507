import { APIClient } from "../helpers/api_helper";
import { GET_ALL_DEVICE, POST_PIN_DEVICE } from "../helpers/url_helper";

const api = new APIClient();

export const getDevices = async () => {
    return await api.get(GET_ALL_DEVICE);
};

export const pinDevice = async (id, isPin) => {
    return await api.create(POST_PIN_DEVICE, { id: id, isPin: isPin });
};
