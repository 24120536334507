import { convertLocationToUI, convertMonitorStatusToDB, convertMonitorStatusToUI, convertMonitorTypeToUI } from "../../common/utils";
import { createEventMessageService, getAllPendingEventsService, getEventMessagesService, getEventReportByReportIDService, getEventReportService, getSavedReportListService, reportEventService, saveAsEventReportService, saveEventReportService } from "../../services/event";
import { apiError, createEventMessageSuccess, getEventMessageSuccess, getEventsReportByIDSuccess, getEventsReportListSuccess, getEventsReportSuccess, getPendingEventsSuccess, saveEventsReportSuccess } from "./reducer";

export const getAllPendingEvents = () => async (dispatch) => {
  try {    
    var data = await getAllPendingEventsService();
    let result = {pending: data.data.pending, events: data.data.events};
    dispatch(getPendingEventsSuccess(result));
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
};

export const getEventMessages = (eventID) => async (dispatch) => {
  try {    
    var result = await getEventMessagesService(eventID);
    dispatch(getEventMessageSuccess({eventID: eventID, result: result.data}));
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
};

export const createEventMessage = (eventID, message) => async (dispatch) => {
  try {    
    var result = await createEventMessageService(eventID, message);
    dispatch(createEventMessageSuccess({eventID: eventID, result: result.data}));
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
};

export const reportEvent = (eventID, isFalseAlarm) => async (dispatch) => {
  try {    
    var result = await reportEventService(eventID, isFalseAlarm);
    return result.data;
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
};

export const getEventReport = (selectedMultiFloorPlan, selectedMultiMonitorType, selectedMultiMonitorStatus, dateRange, pageNumber, pageSize) => async (dispatch) => {
  try {    
    var data = await getEventReportService(selectedMultiFloorPlan, selectedMultiMonitorType, selectedMultiMonitorStatus, dateRange, pageNumber, pageSize);
    console.log(data);
    dispatch(getEventsReportSuccess(data));
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
};

export const getEventReportList = (searchText, sortColumn, sortDirection, pageNumber, pageSize) => async (dispatch) => {
  try {  
    console.log("pageNumber: " + pageNumber);  
    var data = await getSavedReportListService(searchText, sortColumn, sortDirection, pageNumber, pageSize);
    console.log(data);
    dispatch(getEventsReportListSuccess(data));
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
};

export const getEventReportByReportID = (reportID, t, floorplan, monitorType, monitorStatus) => async (dispatch) => {
  try {    
    var data = await getEventReportByReportIDService(reportID);
    let result = {...data.data, selectedMultiFloorPlan: convertLocationToUI(data.data.location, t, floorplan), selectedMultiMonitorType: convertMonitorTypeToUI(data.data.type, t, monitorType), selectedMultiMonitorStatus:  convertMonitorStatusToUI(data.data.status, t, monitorStatus), dateRange: [parseInt(data.data.dateRange[0])*1000, parseInt(data.data.dateRange[1])*1000]};
    dispatch(getEventsReportByIDSuccess(result));
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
};

export const saveEventReport = (reportID, selectedMultiFloorPlan, selectedType, selectedStatus, dateRange, reportName) => async (dispatch) => {
  try {    
    var data = await saveEventReportService(reportID, selectedMultiFloorPlan, selectedType, selectedStatus, dateRange, reportName);
    dispatch(saveEventsReportSuccess(data));
    return data;
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
};