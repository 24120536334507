import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { Button, Table } from "reactstrap";
import { getDevicesByDeveloper } from "../../services/developer";
import DeviceRow from "./DeviceRow";
import { getDisplayDatetime } from "../../helpers/display_help";

function DeviceTable() {
    const { t } = useTranslation();
    document.title = t('PageMain.DocumentTitle');
    const [currentDevices, setCurrentDevices] = useState([]);
    const [lastUpdateDate, setLastUpdatedDate] = useState("");

    async function fetchData() {
        let result = await getDevicesByDeveloper();
        console.log(result);
        if (result.data !== false) {
            setCurrentDevices(result.data);
            setLastUpdatedDate(getDisplayDatetime(new Date().getTime()));
        }
    }

    useEffect(() => {        
        fetchData();
    }, [])

    return (
        <Fragment>
        <div>
            <Button size="sm" color="primary" className="mb-2 me-2" onClick={fetchData}>{t('Common.Btn.Refresh')}</Button>
            {t('PageDeveloper.DeviceTable.LastUpdatedAt') + ": " + lastUpdateDate}
        </div>
        <Table className="table-nowrap mb-0">
            <thead className="table-light">
                <tr>
                    <th scope="col">{t('PageDeveloper.DeviceTable.ID')}</th>
                    <th scope="col">{t('PageDeveloper.DeviceTable.CompanyName')}</th>
                    <th scope="col">{t('PageDeveloper.DeviceTable.Floor')}</th>
                    <th scope="col">{t('PageDeveloper.DeviceTable.Room')}</th>
                    <th scope="col">{t('PageDeveloper.DeviceTable.Location')}</th>
                    <th scope="col">{t('PageDeveloper.DeviceTable.Status')}</th>
                    <th scope="col">{t('PageDeveloper.DeviceTable.StartTime')}</th>
                </tr>
            </thead>
            <tbody>
                {currentDevices.map((d) => {
                    return (
                        <DeviceRow data={d} key={d.id} />
                    );
                })}
            </tbody>
        </Table>
        </Fragment>
    );
}

export default DeviceTable;