import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import colors from "../../assets/scss/config/saas/_colors.scss";
import { MONITOR_STATUS, MONITOR_TYPE } from "../../common/constants";
import { useDispatch, useSelector } from "react-redux";
import { EventBedIcon, EventToiletIcon } from "./SVG";
import { getDisplayDate, getDisplayFloor, getDisplayLocation, getDisplayRoom, getDisplayTime, getDisplayDatetime } from "../../helpers/display_help";
import { createEventMessage, getEventMessages, reportEvent } from "../../slices/event/thunk";

const getStatus = (value, t) => {
    let boxColor = colors.handledDanger;
    if (value === MONITOR_STATUS.TOILET.FALL) {
        boxColor = colors.handledDanger;
    }
    else if (value === MONITOR_STATUS.TOILET.USING_OT) {
        boxColor = colors.handledWarning;
    }
    return (
        <div style={{ backgroundColor: boxColor }} className="dash-event-eventType mr-3 align-self-center">{t('Common.MonitorStatusTypeShortForm.' + value)}</div>
    );
}

const getDeviceStatusIcon = (data) => {
    let iconColor = colors.handledDanger;
    if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
        if (data.eventType === MONITOR_STATUS.TOILET.FALL) {
            iconColor = colors.handledDanger;
        }
        else if (data.eventType === MONITOR_STATUS.TOILET.USING_OT) {
            iconColor = colors.handledWarning;
        }

        return <EventToiletIcon size={24} fill={iconColor} />;
    }
    else {
        if (data.eventType === MONITOR_STATUS.BED.FALL) {
            iconColor = colors.handledDanger;
        }

        return <EventBedIcon size={24} fill={iconColor} />;
    }
}

const getMessageIcon = (data) => {
    return (
        <div className="event-edit-modal-message-icon">
            {data[0].toUpperCase()}
        </div>
    );
}



const EventEditModal = ({ show, onCloseClick, data }) => {
    const { t } = useTranslation();
    const event = useSelector((state => state.Event.events[data]));
    const user = useSelector((state => state.Profile.user));
    const messages = useSelector((state => state.Event.messages[data]));
    const dispatch = useDispatch();
    const [newMessage, setNewMessage] = useState("");
    useEffect(() => {
        dispatch(getEventMessages(data));
    }, [event]);
    if (event === undefined) {
        return null;
    }

    return (
        <Modal isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalBody className="d-flex flex-column">
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row event-edit-modal-event event-edit-modal-row event-edit-modal-row-with-icon">
                        <div className="event-edit-modal-label">{t('EditEventInfoModal.Title')}:</div>
                        <div className="event-edit-modal-event-status">{getStatus(event.eventType, t)}</div>
                        <div className="d-flex flex-row modal-close-btn">
                            <Button type="button" className="btn-close" onClick={() => { onCloseClick(); }} ></Button>
                        </div>
                    </div>
                    <div className="d-flex flex-row event-edit-modal-location event-edit-modal-row event-edit-modal-row-with-icon">
                        <div className="event-edit-modal-label">{t('EditEventInfoModal.Location')}:</div>
                        <div className="d-flex flex-row event-edit-modal-event-location">
                            <div className="event-edit-modal-row-item">{getDeviceStatusIcon(event)}</div>
                            <div className="event-edit-modal-row-item">{getDisplayFloor(event.floorName, t)}</div>
                            <div className="event-edit-modal-row-item">{getDisplayRoom(event, t)}</div>
                            <div className="event-edit-modal-row-item">{getDisplayLocation(event, t)}</div>
                        </div>
                    </div>
                    <div className="d-flex flex-row event-edit-modal-time event-edit-modal-row">
                        <div className="event-edit-modal-label">{t('EditEventInfoModal.TimeBegin')}:</div>
                        <div className="d-flex flex-row">
                            <div className="event-edit-modal-row-item">{getDisplayDate(event.eventStartTime)}</div>
                            <div className="event-edit-modal-row-item">{getDisplayTime(event.eventStartTime)}</div>
                        </div>
                    </div>
                    <div className="d-flex flex-row event-edit-modal-time event-edit-modal-row">
                        <div className="event-edit-modal-label">{t('EditEventInfoModal.TimeArrived')}:</div>
                        {event.eventArrivedTime === undefined ? <div className="event-edit-modal-row-item">-</div> :
                            <div className="d-flex flex-row">
                                <div className="event-edit-modal-row-item">{getDisplayDate(event.eventArrivedTime)}</div>
                                <div className="event-edit-modal-row-item">{getDisplayTime(event.eventArrivedTime)}</div>
                            </div>}
                    </div>
                    <div className="d-flex flex-row event-edit-modal-time event-edit-modal-row">
                        <div className="event-edit-modal-label">{t('EditEventInfoModal.TimeResolved')}:</div>
                        <div className="d-flex flex-row">
                            <div className="event-edit-modal-row-item">{getDisplayDate(event.eventEndTime)}</div>
                            <div className="event-edit-modal-row-item">{getDisplayTime(event.eventEndTime)}</div>
                        </div>
                    </div>
                    <div className="d-flex flex-row event-edit-modal-message event-edit-modal-row">
                        <div className="event-edit-modal-label">{t('EditEventInfoModal.Detail')}</div>
                    </div>
                    <div className="d-flex flex-column event-edit-modal-row event-edit-modal-message-list-container">
                    {messages !== undefined && messages.map(m => {
                        return (
                            <div key={m.id} className="d-flex flex-row event-edit-modal-message">
                                <div className="event-edit-modal-label-message">{getMessageIcon(m.username)}</div>
                                <div className="d-flex flex-column">
                                    <div className="event-edit-modal-message-list-main">{m.content}</div>
                                    <div className="event-edit-modal-message-list-sub">{m.username + " " + getDisplayDatetime(m.updated_at)}</div>
                                </div>
                            </div>
                        );
                    })}
                    </div>
                    <div className="d-flex flex-row event-edit-modal-message">
                        <div className="event-edit-modal-label-message">{getMessageIcon(user.username)}</div>
                        <div className="d-flex flex-column event-edit-modal-label-message-edit">
                            <textarea className="form-control event-edit-modal-label-message-edit-area" id="messageTextArea" rows="3" placeholder={t('EditEventInfoModal.MessagePlaceholder')} value={newMessage} onChange={(event)=>{setNewMessage(event.target.value)}}></textarea>
                            <div className="d-flex flex-row event-edit-modal-row  justify-content-end">
                                <Button className="btn-soft-primary btn-sm event-edit-modal-message-btn" color="primary" onClick={()=>{
                                    if (newMessage.length > 0) {
                                        dispatch(createEventMessage(event.id, newMessage)).then(()=>{
                                            setNewMessage("");
                                        });
                                    }
                                }}> {t('EditEventInfoModal.BtnSave')} </Button>
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-center">
                        <Button className="btn-sm event-edit-modal-btn" color="primary" onClick={()=>{
                            dispatch(reportEvent(event.id, true)).then((isDone)=>{
                                onCloseClick(isDone);
                            });
                        }}> {t('EditEventInfoModal.BtnReportAndClose')} </Button>
                        <Button className="btn-sm event-edit-modal-btn" color="primary"  onClick={()=>{
                             dispatch(reportEvent(event.id, false)).then((isDone)=>{
                                onCloseClick(isDone);
                            });
                        }}> {t('EditEventInfoModal.BtnClose')} </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}

EventEditModal.propTypes = {
    onCloseClick: PropTypes.func,
    data: PropTypes.any,
    show: PropTypes.any,
};

export default EventEditModal;