import { GENDER, LOCATION_TYPE, MONITOR_STATUS, MONITOR_TYPE, TIMEZONE } from "../common/constants";
import moment from "moment-timezone";

export const getDisplayFloor = (value, t) => {
    return value + t('Common.FloorConnector') + t('Common.FloorShortForm');
}

export const getDisplayRoom = (data, t) => {
    let room = "-";
    if (data.locationTypeName === LOCATION_TYPE.ROOM) {
        room = t('Common.RoomNumber') + data.locationName + t('Common.Space') + t('Common.RoomUnit');
    }
    return room;
}

export const getDisplayLocation = (data, t) => {
    if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
        if (data.isForDisable) {
            if (data.forGender) {
                if (data.forGender === GENDER.ALL) {
                    return t('Common.DisableToilet') + t('Common.Space') + t('Common.Toilet') + t('Common.Space') + data.monitorLocationName;
                }
                else {
                    return t('Common.DisableToilet') + t('Common.Space') + t('Common.Gender.'+data.forGender) + t('Common.Space') + t('Common.DisableToilet2') + t('Common.Space') + data.monitorLocationName;
                }
            }
        }
        else {
            return t('Common.Toilet') + t('Common.Space') + data.monitorLocationName;
        }
     }
    else {
        return t('Common.BedNumber') + data.monitorLocationName + t('Common.Space') + t('Common.BedUnit');
    }
}

export const getDisplayDate = (data) => {
    if (data === undefined || data === null) {
        return "-";
    }
    let t = moment.unix(data/1000);
    const formattedTime = t.format('YYYY-MM-DD');
    return formattedTime;
}

export const getDisplayTime = (data) => {    
    if (data === undefined || data === null) {
        return "-";
    }
    let t = moment.unix(data/1000);
    const formattedTime = t.format('HH:mm:ss');
    return formattedTime;
}

export const getDisplayDatetimeFromTimestamp = (milliseconds) => {    
    if (milliseconds === undefined || milliseconds === null) {
        return "-";
    }
    let totalSeconds = Math.floor(milliseconds / 1000);
    let hours = Math.floor(totalSeconds / 3600);
    let minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
    let seconds = totalSeconds - (hours * 3600) - (minutes * 60);
    return hours.toString().padStart(2, '0') + ':' + minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
  }

export const getDisplayDatetime = (data) => {    
    if (data === undefined || data === null) {
        return "-";
    }
    let t = moment.unix(data/1000);
    const formattedTime = t.format('YYYY-MM-DD HH:mm:ss');
    return formattedTime;
}

export const isGoFollowUp = (status, device) => {
    if (device.isConnected) {
        if (status === MONITOR_STATUS.TOILET.FALL || status === MONITOR_STATUS.TOILET.FROZEN_FALL || status === MONITOR_STATUS.TOILET.USING_OT || status === MONITOR_STATUS.TOILET.FROZEN_USING_OT || status === MONITOR_STATUS.BED.FALL || status === MONITOR_STATUS.BED.FROZEN_FALL) {
            return true;
          }
          else {
            return false;
          }
    }
    else {
        return false;
    }
}