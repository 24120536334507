import { useState } from "react";
import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { useTranslation } from "react-i18next";

const ConfirmModal = ({ show, onCloseClick, onConfirmClick, title, description }) => {
    const { t } = useTranslation();
    const [isShowError, setShowError] = useState(false);

    const closeModal = (isRedirect) => {
        setShowError(false);
        onCloseClick(isRedirect);
    }

    return (
        <Modal className="confirm-modal" isOpen={show} toggle={() => { closeModal(false) }} centered={true}>
            <div className="modal-header">
                <div className="modal-title" id="varyingcontentModalLabel">{title}</div>
                
                <div className="d-flex flex-row modal-close-btn">
                    <Button type="button" className="btn-close btn-sm btn-light" onClick={() => { closeModal(false); }} ></Button>
                </div>
            </div>
            <ModalBody className="d-flex flex-column">
                <div className="d-flex flex-column align-items-center confirm-modal-btn-container">

                    <div className="confirm-modal-description">{description}</div>
                    {isShowError ? <div className="confirm-modal-error">{t('Common.ConfirmModal.Error')}</div> : null}
                    <div className="d-flex flex-row justify-content-center ">
                        <Button className="btn-sm confirm-modal-btn" color="primary" onClick={() => {
                            closeModal(false)
                        }}> {t('Common.ConfirmModal.BtnCancel')}</Button>
                        <Button className="btn-sm confirm-modal-btn" color="primary" onClick={() => {
                            onConfirmClick().then(data => {
                                if (data.data === false) {
                                    setShowError(true);
                                }
                                else {
                                    closeModal(true)
                                }
                            }
                            )
                        }}> {t('Common.ConfirmModal.BtnConfirm')} </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
}

export default ConfirmModal;