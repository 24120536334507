//LOGIN
export const POST_LOGIN = "/auth/login";

//DEVICE
export const GET_FLOORPLAN = "/static/floorplan";
export const GET_MONITOR_TYPE = "/static/monitortype";
export const GET_MONITOR_STATUS = "/static/monitorstatus";
export const GET_CARE_REQUIREMENT = "/static/caretype";
export const GET_MONITOR_PERIOD = "/static/monitorperiod";
export const GET_APPLIED_RULES = "/static/appliedrules";
export const GET_REPORT_MONITOR_STATUS = "/static/monitorstatusforreport";

//DEVICES
export const GET_ALL_DEVICE = "/monitor/all";
export const POST_PIN_DEVICE = "/monitor/pin";

//EVENT
export const GET_ALL_PENDING_EVENT = "/event/pending";
export const REPORT_EVENT = "/event/report";
export const CREATE_EVENT_MESSAGE = "/event/message";
export const GET_EVENT_MESSAGE = "/event/message";
export const GET_EVENT_REPORT = "/event/report";
export const POST_EVENT_REPORT_SAVE = "/event/reportsave";
export const GET_EVENT_REPORT_LIST = "/event/reportlist";
export const DELETE_EVENT_REPORT = "/event/report";
export const GET_EVENT_REPORT_EXPORT = "/event/reportexport";

//SUMMARY
export const GET_SUMMARY_TOILET_FALL = "/monitor/summary/toiletfall";
export const GET_SUMMARY_TOILET_OT = "/monitor/summary/toiletot";
export const GET_SUMMARY_TOILET_ARRIVAL = "/monitor/summary/toiletarrival";
export const GET_SUMMARY_BED_FALL = "/monitor/summary/bedfall";
export const GET_SUMMARY_BED_ARRIVAL = "/monitor/summary/bedarrival";

//DEVELOPER
export const GET_DEVELOPER_DEVICES = "/developer/devices";



