const isDev = process.env.NODE_ENV === "development";
const isStage = process.env.REACT_APP_STAGE === "stage";

module.exports = {
  google: {
    API_KEY: "",
    CLIENT_ID: "",
    SECRET: "",
  },
  facebook: {
    APP_ID: "",
  },
  api: {
    API_URL: isDev ? "http://192.168.0.20:3002": isStage? "https://api.stage.fadelisy.bnet-tech.com" : "https://api.fadelisy.bnet-tech.com",
  },
  socket: {
    SOCKET_URL: isDev ? "ws://192.168.0.20:3002" : isStage? "wss://api.stage.fadelisy.bnet-tech.com" : "wss://api.fadelisy.bnet-tech.com",
    SOCKET_PATH: isDev ? "/socket.io/" : isStage? "/socket.io/" : "/socket.io/",
  }
};