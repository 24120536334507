import { socket } from "../config";
import { io } from "socket.io-client";


export default class DataConnector {
    static instance = null;

    static getInstance() {
        if (this.instance === null) {
            this.instance = new DataConnector();
        }
        return this.instance;
    }

    socket = null;

    onConnected = null;
    onDisconnected = null;
    onMQConnected = null;
    onMQDisconnected = null;
    onDeviceConnected = null;
    onDeviceDisconnected = null;
    onDeviceStatusChange = null;
    onReportUpdate = null;
    onPinUpdate = null;
    onForceReFresh = null;
    log = (tb, ...args) => {
        console.log(`[${this.NAME}-${tb}]`, ...args)
    }

    constructor() {
        this.log('constructor', `Socket connecting to ${socket.SOCKET_URL} ${socket.SOCKET_PATH}`);
    }

    createSocket() {        
        if (this.socket !== null && this.socket.connected) {
            this.closeSocket();
        }
        let token = JSON.parse(sessionStorage.getItem("authUser")).credential.accessToken;
        try {
            this.socket = io(socket.SOCKET_URL, {
                query: 'token=' + token
            });
        }
        catch (error) {
            console.log("socket error")
            console.log(error);
        }
    }

    closeSocket() {
        if (this.socket !== null) {
            this.socket.disconnect();
        }
    }

    setCBs(onConnected, onDisconnected, onMQConnected, onMQDisconnected, onDeviceConnected, onDeviceDisconnected, onDeviceStatusChange, onReportUpdate, onPinUpdate, onForceReFresh) {
        this.onConnected = onConnected;
        this.onDisconnected = onDisconnected;
        this.onMQConnected = onMQConnected;
        this.onMQDisconnected = onMQDisconnected;
        this.onDeviceConnected = onDeviceConnected;
        this.onDeviceDisconnected = onDeviceDisconnected;
        this.onDeviceStatusChange = onDeviceStatusChange;
        this.onReportUpdate = onReportUpdate;
        this.onPinUpdate = onPinUpdate;
        this.onForceReFresh = onForceReFresh;
    }

    setup() {
        this.socket.on("connect", () => {
            console.info("Socket Connected", this.socket.id);
            this.onConnected();
        })
        this.socket.on("disconnect", (reason, description) => {
            console.info("Socket Disconnected", reason, description);
            this.onDisconnected();
        })
        this.socket.on("connect_error", (error) => {
            console.info("Socket Connect Error", error.name, error.message);
        })
        this.socket.on('MQ_CONNECTED', () => {
            console.info("Socket: MQ_CONNECTED");
            this.onMQConnected?.();
        })
        this.socket.on('MQ_DISCONNECTED', () => {
            console.info("Socket: MQ_DISCONNECTED");
            this.onMQDisconnected?.();
        })
        this.socket.on('DEVICE_CONNECTED', (data) => {
            console.info("Socket: DEVICE_CONNECTED", data);
            this.onDeviceConnected?.(data);
        })
        this.socket.on('DEVICE_DISCONNECTED', (data) => {
            console.info("Socket: DEVICE_DISCONNECTED",  data);
            this.onDeviceDisconnected?.(data);
        })
        this.socket.on('DEVICE_STATUS_CHANGE', (data) => {
            console.info("Socket: DEVICE_STATUS_CHANGE", data);
            this.onDeviceStatusChange?.(data);
        })
        this.socket.on('DEVICE_REPORT_UPDATE', (id) => {
            console.info("Socket: DEVICE_REPORT_UPDATE", id);
            this.onReportUpdate?.(id);
        })
        this.socket.on('DEVICE_PIN_UPDATE', (data) => {
            console.info("Socket: DEVICE_PIN_UPDATE", data);
            this.onPinUpdate?.(data);
        })
        this.socket.on('REFRESH', () => {
            console.info("Socket: REFRESH");
            this.onForceReFresh?.();
        })
    }
}