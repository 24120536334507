import { Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import { getDisplayDate, getDisplayFloor, getDisplayLocation, getDisplayRoom, getDisplayTime } from "../../helpers/display_help";
import { useSelector } from "react-redux";
import { MONITOR_STATUS, MONITOR_TYPE } from "../../common/constants";
import colors from "../../assets/scss/config/saas/_colors.scss";
import { EventBedIcon, EventToiletIcon } from "../../Components/Common/SVG";
import FollowUpTimer from "./FollowUpTimer";

const getStatus = (data, t) => {
    let value = data.currentMonitorStatus;
    let boxColor = colors.eventDanger;
    let text = MONITOR_STATUS.TOILET.FALL;
    if (data.isConnected) {
        if (value === MONITOR_STATUS.TOILET.FALL || value === MONITOR_STATUS.BED.FALL) {
            boxColor = colors.eventDanger;
            text = MONITOR_STATUS.TOILET.FALL;
        }
        else if (value === MONITOR_STATUS.TOILET.FROZEN_FALL | value === MONITOR_STATUS.BED.FROZEN_FALL) {
            boxColor = colors.eventDanger;
            text = MONITOR_STATUS.TOILET.FALL;
        }
        else if (value === MONITOR_STATUS.TOILET.USING_OT) {
            boxColor = colors.eventAbnormal;
            text = MONITOR_STATUS.TOILET.USING_OT;
        }
        else if (value === MONITOR_STATUS.TOILET.FROZEN_USING_OT) {
            boxColor = colors.eventAbnormal;
            text = MONITOR_STATUS.TOILET.USING_OT;
        }
        text = t('Common.MonitorStatusTypeShortForm.' + text);
    }
    else {
        text = t('Common.Connection.Off');
        boxColor = colors.eventSystemError;
    }

    return (
        <div style={{ backgroundColor: boxColor }} className="dash-event-eventType mr-3 align-self-center">{text}</div>
    );
}

const getDeviceStatusIcon = (data) => {
    let iconColor = colors.eventDanger;
    if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
        if (data.isConnected) {
            if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_FALL || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FALL) {
                iconColor = colors.eventDanger;
            }
            else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_USING_OT) {
                iconColor = colors.eventAbnormal;
            }
        }
        else {
            iconColor = colors.eventSystemError;
        }

        return <EventToiletIcon scale={1.2} fill={iconColor} />;
    }
    else {
        if (data.isConnected) {
            if (data.currentMonitorStatus === MONITOR_STATUS.BED.FROZEN_FALL || data.currentMonitorStatus === MONITOR_STATUS.BED.FALL) {
                iconColor = colors.eventDanger;
            }
        }
        else {
            iconColor = colors.eventSystemError;
        }

        return <EventBedIcon scale={1.2} fill={iconColor} />;
    }
}

const getText = (isConnected, value) => {
    if (isConnected) {
        return <div className="dash-event-card-text-highlight">{value}</div>
    }
    return <div>{value}</div>
}

const getEventHandleStatus = (data, t) => {
    let value = data.currentMonitorStatus;
    let boxColor = colors.eventDanger;
    if (data.isConnected) {
        if (value === MONITOR_STATUS.TOILET.FALL || value === MONITOR_STATUS.BED.FALL) {
            boxColor = colors.eventDanger;
        }
        else if (value === MONITOR_STATUS.TOILET.FROZEN_FALL || value === MONITOR_STATUS.BED.FROZEN_FALL) {
            boxColor = colors.eventAbnormal;
        }
        else if (value === MONITOR_STATUS.TOILET.USING_OT) {
            boxColor = colors.eventDanger;
        }
        else if (value === MONITOR_STATUS.TOILET.FROZEN_USING_OT) {
            boxColor = colors.eventAbnormal;
        }
        return (
            <div style={{ backgroundColor: boxColor, flex: 4 }} className="dash-event-handle-status mr-3 align-self-center">{t('Common.MonitorHandleStatusType.' + value)}</div>
        );
    }
    else {
        return (
            <div className="dash-event-handle-status mr-3 align-self-center" style={{ flex: 4 }}></div>
        );
    }
}

function FollowUpCard(props) {
    const { t } = useTranslation();

    const deviceID = props.data;

    const data = useSelector((state => state.Device.devices[deviceID]));
    return (
        <Card className="dash-event-card">
            <CardBody className="dash-event-container">
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row justify-content-between flex-wrap">
                        <div style={{minWidth: 80 }} className="d-flex flex-row justify-content-center align-item-center">
                            {getStatus(data, t)}
                            <div style={{minWidth: 80 }} className="dash-event-card-text dash-event-card-text-1 dash-event-card-text-highlight mr-3">{getDisplayDate(data.statusStarttime)}</div>
                            <div style={{minWidth: 80 }} className="dash-event-card-text dash-event-card-text-1 mr-3">{getDisplayTime(data.statusStarttime)}</div>
                        </div>
                    </div>
                    <div className="d-flex flex-row mt-2 flex-wrap dash-event-card-second-line">
                        <div className="dash-event-card-status-icon" style={{ flex: 1, minWidth: 40 }}>{getDeviceStatusIcon(data)}</div>
                        <div className="dash-event-card-text mr-3" style={{ flex: 3, minWidth: 45 }}>{getText(data.isConnected, getDisplayFloor(data.floorName, t))}</div>
                        <div className="dash-event-card-text mr-3" style={{ flex: 3, minWidth: 45 }}>{getText(data.isConnected, getDisplayRoom(data, t))}</div>
                        <div className="dash-event-card-text mr-3" style={{ flex: 3, minWidth: 45 }}>{getText(data.isConnected, getDisplayLocation(data, t))}</div>
                        <div className="dash-event-card-text mr-3" style={{ flex: 5, minWidth: 60 }}>{getText(data.isConnected, data.carerName !== null ? data.carerName : "-")}</div>
                        <div className="dash-event-card-text mr-3 d-flex flex-row justify-content-between align-item-center" style={{ flex: 5, minWidth: 80 }}><FollowUpTimer data={data} /></div>
                        {getEventHandleStatus(data, t)}
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default FollowUpCard;