import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";
import colors from "../../assets/scss/config/saas/_colors.scss";
import DashboardSectionHeader from "./DashboardSectionHeader";
import { getStaticFloorPlan, getStaticMonitorStatus, getStaticMonitorType } from "../../slices/staticData/thunk";
import { getAllDevices } from "../../slices/device/thunk";
import DeviceCard from "./DeviceCard";
import { FilterIcon } from "../../Components/Common/SVG";
import DeviceFilter, { getAllFloorOption, getAllStatusOption, getAllTypeOption } from "../../Components/Common/DeviceFilter";
import { filterDevices } from "../../slices/device/reducer";

const DeviceSection = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const totalDevices = useSelector((state => state.Device.normal.totalDevices));
    const currentDevices = useSelector((state => state.Device.normal.devices));
    
    const floorplan = useSelector((state => state.StaticData.floorplan));
    const monitorType = useSelector((state => state.StaticData.monitorType));    
    const monitorStatus = useSelector((state => state.StaticData.monitorStatus));

    const [selectedMultiFloorPlan, setSelectedMultiFloorPlan] = useState([getAllFloorOption(t)]);
    const [selectedMultiMonitorType, setSelectedMultiMonitorType] = useState([getAllTypeOption(t)]);
    const [selectedMultiMonitorStatus, setSelectedMultiMonitorStatus] = useState([getAllStatusOption(t)]);

    //const [isDeviceEditModelOpen, setDeviceEditModelOpen] = useState(false);
    //const [editDeviceInfo, setEditDeviceInfo] = useState(currentDevices[0]);
    const isMQConnected = useSelector(state => state.Device.isMQConnected);
    const isSocketConnected = useSelector(state => state.Device.isSocketConnected);

    useEffect(() => {
        if (isMQConnected && isSocketConnected) {
            dispatch(getStaticFloorPlan());
            dispatch(getStaticMonitorType());
            dispatch(getStaticMonitorStatus());
            dispatch(getAllDevices());
        }
    }, [isMQConnected, isSocketConnected]);

    useEffect(() => {
        dispatch(filterDevices({ floor: selectedMultiFloorPlan, status: selectedMultiMonitorStatus, monitorType: selectedMultiMonitorType }));
    }, [selectedMultiFloorPlan,  selectedMultiMonitorType, selectedMultiMonitorStatus]);

    return (
        <Card className="section-card">
            <CardBody className="section-cardbody">
                <div className="d-flex flex-column section-container">
                    <div className="d-flex justify-content-between">
                        <DashboardSectionHeader title={t('PageMain.Device.Title')} bed={totalDevices.BED + t('Common.Space1') + (totalDevices.BED > 1 ? t('Common.Beds') : t('Common.Bed'))} toilet={totalDevices.TOILET + t('Common.Space1') + (totalDevices.TOILET > 1 ? t('Common.Toilets') : t('Common.Toilet'))} />
                        <div className="p-2 d-flex flex-row">
                            {/*<UncontrolledDropdown>
                                <DropdownToggle tag="button" className="btn dash-sort-icon" id="dropdownSortButton">
                                    <i className="bx bx-sort-down dash-device-header-icon"></i>
                                </DropdownToggle>
                                <DropdownMenu className="p-3 dash-sort-container">
                                    <div className="dash-sort-title">{t('PageMain.Device.SortName')}</div>
                                    <div className="form-check mb-2">
                                        <Input className="form-check-input" type="radio" name="radioSortBy" id="radioSortBy1" />
                                        <Label className="form-check-label" htmlFor="radioSortBy1">
                                            {t('PageMain.Device.SortByBed')}
                                        </Label>
                                    </div>
                                    <div className="form-check">
                                        <Input className="form-check-input" type="radio" name="radioSortBy" id="radioSortBy2" defaultChecked />
                                        <Label className="form-check-label" htmlFor="radioSortBy2">
                                            {t('PageMain.Device.SortByStatus')}
                                        </Label>
                                    </div>
                                    <hr className="dash-sort-hr"></hr>
                                    <div className="form-check mb-2">
                                        <Input className="form-check-input" type="radio" name="radioSorting" id="radioSorting1" />
                                        <Label className="form-check-label" htmlFor="radioSorting1">
                                            {t('PageMain.Device.SortAscending')}
                                        </Label>
                                    </div>
                                    <div className="form-check">
                                        <Input className="form-check-input" type="radio" name="radioSorting" id="radioSortin2" defaultChecked />
                                        <Label className="form-check-label" htmlFor="radioSorting2">
                                            {t('PageMain.Device.SortDescending')}
                                        </Label>
                                    </div>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            <i className="bx bx-dots-horizontal-rounded dash-device-header-icon"></i>
                            */}
                        </div>
                    </div>
                    <div className="d-flex align-items-center flex-wrap dash-filter-container">
                        <div className="dash-filter-icon">
                            <FilterIcon scale={1.2} fill={colors.offText} />
                        </div>
                        <DeviceFilter 
                            floorplan={floorplan} monitorType={monitorType} monitorStatus={monitorStatus}
                            selectedMultiFloorPlan={selectedMultiFloorPlan} 
                            selectedMultiMonitorType={selectedMultiMonitorType} 
                            selectedMultiMonitorStatus={selectedMultiMonitorStatus} 
                            setSelectedMultiFloorPlan={setSelectedMultiFloorPlan}
                            setSelectedMultiMonitorType={setSelectedMultiMonitorType}
                            setSelectedMultiMonitorStatus={setSelectedMultiMonitorStatus}
                           />
                    </div>
                    <div className="d-flex flex-column align-items-center card-container">
                        {currentDevices.length > 0 && currentDevices.map((data) => {
                            if (data.isShow === undefined || data.isShow) {
                                return (
                                    <DeviceCard key={data} data={data} />
                                );
                            }
                        })}
                    </div>
                </div>
            </CardBody>
            {/*<DeviceEditModal info={editDeviceInfo} isDeviceEditModelOpen={isDeviceEditModelOpen} setDeviceEditModelOpen={(value) => {
                setDeviceEditModelOpen(value);
            }} />*/}
        </Card>
    )
}

export default DeviceSection;