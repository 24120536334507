
import moment from "moment";
import { getDevices } from "../../services/device";
import { apiError, getDeviceSuccess } from "./reducer";

export const getAllDevices = () => async (dispatch) => {
  try {    
    var data = await getDevices();
    let timer = {};
    let result = {normal: data.data.normal, followUp: data.data.followUp, devices: data.data.devices, timer: timer};
    if (data) {
      let now = moment().valueOf();
      for (let key in data.data.devices) {
        let element = data.data.devices[key];
        if (element["statusStarttime"] === null || element["statusStarttime"] === 0) {
          timer[element["id"]] = null;
        }
        else {
          timer[element["id"]] = now - element["statusStarttime"];
        }
      };
    }
    dispatch(getDeviceSuccess(result));
  } catch (error) {
    console.log(error)
    dispatch(apiError(error));
  }
};
