import { Card, CardBody } from "reactstrap";
import { useTranslation } from "react-i18next";
import { getDisplayDate, getDisplayFloor, getDisplayLocation, getDisplayRoom, getDisplayTime } from "../../helpers/display_help";
import { useSelector } from "react-redux";
import { MONITOR_STATUS, MONITOR_TYPE } from "../../common/constants";
import colors from "../../assets/scss/config/saas/_colors.scss";
import { EditIcon, EventBedIcon, EventToiletIcon } from "../../Components/Common/SVG";

const getStatus = (value, t) => {
    let boxColor = colors.handledDanger;
    if (value === MONITOR_STATUS.TOILET.FALL || value === MONITOR_STATUS.BED.FALL) {
        boxColor = colors.handledDanger;
    }
    else if (value === MONITOR_STATUS.TOILET.USING_OT) {
        boxColor = colors.handledWarning;
    }
    return (
        <div style={{ backgroundColor: boxColor }} className="dash-event-eventType mr-3 align-self-center">{t('Common.MonitorStatusTypeShortForm.' + value)}</div>
    );
}

const getDeviceStatusIcon = (data) => {
    let iconColor = colors.handledDanger;
    if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
        if (data.eventType === MONITOR_STATUS.TOILET.FALL) {
            iconColor = colors.handledDanger;
        }
        else if (data.eventType === MONITOR_STATUS.TOILET.USING_OT) {
            iconColor = colors.handledWarning;
        }

        return <EventToiletIcon scale={1.2} fill={iconColor} />;
    }
    else {
        if (data.eventType === MONITOR_STATUS.BED.FALL) {
            iconColor = colors.handledDanger;
        }

        return <EventBedIcon scale={1.2} fill={iconColor} />;
    }
}

const getEventHandleStatus = (t) => {
    let boxColor = colors.handledResolved;
    return (
        <div style={{ color: boxColor }} className="dash-event-handle-status mr-3 align-self-center">{t('Common.Resolved')}</div>
    );
}

function HistoryCard(props) {
    const { t } = useTranslation();
    const eventID = props.data;
    const data = useSelector((state => state.Event.events[eventID]));

    return (
        <Card className="dash-event-card" onClick={() => { props.openEditModal(data.id); }}>
            <CardBody className="dash-event-container">
                <div className="d-flex flex-column">
                    <div className="d-flex flex-row justify-content-between flex-wrap">
                        <div style={{minWidth: 70}} className="d-flex flex-row justify-content-center align-item-center">
                            {getStatus(data.eventType, t)}
                            <div style={{minWidth: 110}} className="dash-event-card-text dash-event-card-text-1 dash-event-card-text-highlight mr-3">{getDisplayDate(data.eventStartTime)}</div>
                            <div style={{minWidth: 70}} className="dash-event-card-text dash-event-card-text-1 mr-3">{getDisplayTime(data.eventStartTime)}</div>
                            <div style={{minWidth: 70}} className="dash-event-card-text dash-event-card-text-1 mr-3">{getDisplayTime(data.eventEndTime)}</div>
                        </div>
                    </div>
                    <div className="d-flex flex-row mt-2 flex-wrap dash-event-card-second-line">
                        <div className="dash-event-card-status-icon" style={{ flex: 1, minWidth: 40 }}>{getDeviceStatusIcon(data)}</div>
                        <div className="dash-event-card-text dash-event-card-text-highlight mr-3" style={{ flex: 3, minWidth: 45 }}>{getDisplayFloor(data.floorName, t)}</div>
                        <div className="dash-event-card-text dash-event-card-text-highlight mr-3" style={{ flex: 3, minWidth: 45 }}>{getDisplayRoom(data, t)}</div>
                        <div className="dash-event-card-text dash-event-card-text-highlight mr-3" style={{ flex: 3, minWidth: 45 }}>{getDisplayLocation(data, t)}</div>
                        <div className="dash-event-card-text dash-event-card-text-highlight mr-3" style={{ flex: 4, minWidth: 40 }}>{data.carerName !== null ? data.carerName: "-"}</div>
                        {getEventHandleStatus(t)}
                        <div className="dash-event-card-text mr-3 dash-history-edit-btn dash-event-card-text-highlight" style={{ flex: 1 }}>
                            <EditIcon scale={1.1} fill={colors.handledResolved} />
                        </div>
                    </div>
                </div>
            </CardBody>
        </Card>
    );
}

export default HistoryCard;