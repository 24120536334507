import { useTranslation } from "react-i18next";
import { getDisplayDate, getDisplayDatetime, getDisplayDatetimeFromTimestamp, getDisplayFloor, getDisplayLocation, getDisplayRoom, getDisplayTime } from "../../helpers/display_help";
import { MONITOR_STATUS, MONITOR_TYPE } from "../../common/constants";
import colors from "../../assets/scss/config/saas/_colors.scss";

const getStatus = (value, t) => {
    let boxColor = colors.handledDanger;
    if (value === MONITOR_STATUS.TOILET.FALL || value === MONITOR_STATUS.BED.FALL) {
        boxColor = colors.handledDanger;
    }
    else if (value === MONITOR_STATUS.TOILET.USING_OT) {
        boxColor = colors.handledWarning;
    }
    else if (value === MONITOR_STATUS.TOILET.USING) {
        boxColor = colors.eventToiletNormal;
    }
    return (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ backgroundColor: boxColor }} className="report-event-eventType mr-3 align-self-center">{t('Common.MonitorStatusTypeShortForm.' + value)}</div>
        </div>
    );
}

const getDescription = (data, t) => {
    if (data.isConnected) {
        if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
            if (data.currentMonitorStatus === null) {
                return <div className="dash-device-card-text-toilet-idle">-</div>;
            }
            else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.IDLE) {
                return <div className="dash-device-card-text-toilet-idle">{t('Common.MonitorStatusTypeShortForm.'+data.currentMonitorStatus)}</div>;
            }
            else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING) {
                return <div className="dash-device-card-text-toilet-using">{t('Common.MonitorStatusTypeShortForm.'+data.currentMonitorStatus)}</div>;
            }
            else {
                return <div></div>
            }
        }
        else {
            if (data.currentMonitorStatus === null) {
                return <div className="dash-device-card-text-toilet-idle">-</div>;
            }
            else if (data.currentMonitorStatus === MONITOR_STATUS.BED.IDLE) {
                return <div className="dash-device-card-text-toilet-idle">{t('Common.MonitorStatusTypeShortForm.'+data.currentMonitorStatus)}</div>;
            }
            else {
                return <div></div>
            }
        }
    }
    else {
        return <div className="dash-device-card-text-nosignal">{t('Common.Connection.Off')}</div>
    }
}

function DeviceRow(props) {
    const { t } = useTranslation();
    const data = props.data

    return (
        <tr>
            <td className="fw-medium">{data["id"]}</td>
            <td>{data["companyName"]}</td>
            <td>{getDisplayFloor(data["floorName"], t)}</td>
            <td>{getDisplayRoom(data, t)}</td>
            <td>{getDisplayLocation(data, t)}</td>
            <td>{getDescription(data, t)}</td>
            <td>{getDisplayDatetime(data["statusStarttime"])}</td>
        </tr>
    );
}

export default DeviceRow;