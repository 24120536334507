import { useSelector } from "react-redux";

const DashboardSectionHeader = (props) => {
    const isMQConnected = useSelector(state => state.Device.isMQConnected);
    const isSocketConnected = useSelector(state => state.Device.isSocketConnected);
    return (
        <div className="p-2 d-flex flex-column mb-2">
            <div className="dash-device-title d-flex flex-row align-items-center">
                {props.title} 
                {isSocketConnected?isMQConnected?null:<i className='bx bxs-error-alt mq-connection-icon'></i>:<i className='bx bxs-error-alt socket-connection-icon'></i>}
            </div>
            <div className="dash-device-subtile">
                {props.bed + " | " + props.toilet}
            </div>
        </div>
    );
}

export default DashboardSectionHeader;