import React  from "react";
import { MONITOR_STATUS, MONITOR_TYPE } from "../../common/constants";
import { useSelector } from "react-redux";
import { getDisplayDatetimeFromTimestamp } from "../../helpers/display_help";
import colors from "../../assets/scss/config/saas/_colors.scss";

const FollowUpTimer = ({data}) => {
    const timer = useSelector((state => state.Device.timer[data.id]));
    const timerString = timer !==null?getDisplayDatetimeFromTimestamp(timer):"-";
    let iconColor = colors.eventDanger;
    if (data.isConnected) {
        if (data.monitorTypeID === MONITOR_TYPE.TOILET) {
            if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_FALL || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FALL) {
                iconColor = colors.eventDanger;
            }
            else if (data.currentMonitorStatus === MONITOR_STATUS.TOILET.USING_OT || data.currentMonitorStatus === MONITOR_STATUS.TOILET.FROZEN_USING_OT) {
                iconColor = colors.eventAbnormal;
            }      
            
            return <div style={{display: 'flex', alignItems: 'center', color: iconColor, marginRight: 12 }} >{timerString}</div>;
        }
        else {
            if (data.currentMonitorStatus === MONITOR_STATUS.BED.FROZEN_FALL || data.currentMonitorStatus === MONITOR_STATUS.BED.FALL) {
                iconColor = colors.eventDanger;
            }    
            
            return <div style={{display: 'flex', alignItems: 'center', color: iconColor, marginRight: 12 }} >{timerString}</div>;
        }
    }
    else {
        return <div style={{display: 'flex', alignItems: 'center', color: colors.eventSystemError, marginRight: 12 }} >{timerString}</div>
    }
}

export default FollowUpTimer;