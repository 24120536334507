import React, { useEffect, useState } from "react";
import { Button, Input, Row, Table } from "reactstrap";
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from "react-redux";

import { PaginationControl } from 'react-bootstrap-pagination-control';
import { getEventReportList } from "../../slices/event/thunk";
import { REPORT_PAGE_SIZE, SORT } from "../../common/constants";
import { deleteSavedReportService, exportEventReportByReportIDService } from "../../services/event";
import ReportListRow from "./ReportListRow";
import ConfirmModal from "../../Components/Common/ConfirmModal";
import { REPORT_TAB_OVERVIEW } from ".";
import { updateSavedEventListTableParameters, updateSelectedReportID } from "../../slices/event/reducer";

export const REPORT_LIST_COL_NAME = {
    NAME: {
        id: 0,
        name: "name"
    },
    CREATED_AT: {
        id: 1,
        name: "updated_at"
    }
}

function ReportListTable(props) {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    document.title = t('PageMain.DocumentTitle');
    const report = useSelector(state => state.Event.savedReportList);
    const selectedReportID = useSelector(state => state.Event.report.reportID);
    const data = report.data;
    const [isOpenConfirmModal, setOpenConfirmModal] = useState(false);
    const [selectedReport, setSelectedReport] = useState(null);
    const page = report.pageNumber;
    const searchText = report.searchText;
    const sortColumn = report.sortColumn;
    const sortDirection = report.sortDirection;
    const lastUpdated = report.lastUpdated;
    const setSortParameter= (newSearchText, newSortColoumn, newSortDirection, newPage) => {
        dispatch(updateSavedEventListTableParameters({searchText: newSearchText, sortColumn: newSortColoumn, sortDirection: newSortDirection, pageNumber: newPage}))
    }

    useEffect(()=>{
        initSortedTable();
    }, [lastUpdated]);

    useEffect(() => {
        console.log("page1: " + page);
        dispatch(getEventReportList(searchText, sortColumn.name, sortDirection, page, REPORT_PAGE_SIZE));
    }, [page, searchText, sortColumn, sortDirection]);

    const sortTable = (col) => {
        const table = document.getElementById("report-list-table");
        if (sortColumn === col) {
            if (sortDirection === SORT.ASE) {
                setSortParameter(searchText, sortColumn, SORT.DESC, page)
                table.getElementsByTagName("th")[col.id].setAttribute("data-sort", "desc");
            }
            else {
                setSortParameter(searchText, sortColumn, SORT.ASE, page)
                table.getElementsByTagName("th")[col.id].setAttribute("data-sort", "asc");
            }
        }
        else {
            setSortParameter(searchText, col, SORT.ASE, page);
            table.getElementsByTagName("th")[col.id].setAttribute("data-sort", "asc");
        }   

        const headerCells = table.getElementsByTagName("th");
            for (let i = 0; i < headerCells.length; i++) {
            if (i !== col.id) {
                headerCells[i].setAttribute("data-sort", "none");
            }
        }
    }

    const initSortedTable = () => {
        const table = document.getElementById("report-list-table");
        const headerCells = table.getElementsByTagName("th");
            for (let i = 0; i < headerCells.length; i++) {
                headerCells[i].setAttribute("data-sort", "none");
        }

        for (let key in REPORT_LIST_COL_NAME) {
            let obj = REPORT_LIST_COL_NAME[key];
            if (obj.name === sortColumn) {
                table.getElementsByTagName("th")[obj.id].setAttribute("data-sort", sortDirection);
            }
        }        
    }

    return (
        <Row className="report-save-table">
            <div className="report-table-container"> 
                <div className="search-btn-container">               
                    <div className="input-group input-group-sm search-btn-group">                        
                        <i className="bx bx-search-alt-2 search-icon"></i>
                        <Input type="text" placeholder={t('Common.Btn.Search')} value={searchText} className="form-control search-input" onChange={(event) => {setSortParameter(event.target.value, sortColumn, sortDirection, page)}} />
                    </div>
                </div>
                <div className="report-table report-list-table">
                    <Table className="table-nowrap mb-0" id="report-list-table">
                        <thead className="table-light">
                            <tr>
                                <th scope="col" onClick={()=>sortTable(REPORT_LIST_COL_NAME.NAME)} data-sort="none">
                                    <div className="d-flex flex-row justify-content-center align-items-center">
                                        {t('PageReport.SavedReportTable.Name')} 
                                        <div className="d-flex flex-column sort-icon-container">
                                            <span className="sort-icon asc"></span>
                                            <span className="sort-icon desc"></span>
                                        </div>
                                    </div>
                                </th>
                                <th scope="col"  onClick={()=>sortTable(REPORT_LIST_COL_NAME.CREATED_AT)}  data-sort="none">
                                    <div className="d-flex flex-row justify-content-center align-items-center">
                                        {t('PageReport.SavedReportTable.CreatedTime')} <div className="d-flex flex-column sort-icon-container">
                                            <span className="sort-icon asc"></span>
                                            <span className="sort-icon desc"></span>
                                        </div>
                                    </div>
                                    </th>
                                <th scope="col">{t('PageReport.SavedReportTable.Action')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.map((d) => {
                                return (
                                    <ReportListRow key={d["id"]} data={d} onDelete={() => {
                                        setSelectedReport(d);
                                        setOpenConfirmModal(true);
                                    }} onView={() => {
                                        if (d.id === selectedReportID?.id) {
                                            props.toggleTag(REPORT_TAB_OVERVIEW);
                                        }
                                        else {
                                            dispatch(updateSelectedReportID(d));
                                        }
                                    }} 
                                    onExport={()=>{
                                        exportEventReportByReportIDService(d["id"], i18n.language, d.name);                                        
                                    }}/>
                                );
                            })}
                        </tbody>
                    </Table>
                </div>
            </div>
            <div className="report-pagination-container">
                <PaginationControl
                    page={parseInt(page)}
                    between={4}
                    total={parseInt(report.totalReports)}
                    limit={REPORT_PAGE_SIZE}
                    changePage={(page) => {
                        setSortParameter(searchText, sortColumn, sortDirection, page)
                    }}
                    ellipsis={1}
                />
            </div>
            <ConfirmModal
                show={isOpenConfirmModal}
                onCloseClick={(isDone) => {
                    if (isDone) {
                        dispatch(getEventReportList(searchText, sortColumn.name, sortDirection, page, REPORT_PAGE_SIZE));
                    }
                    setOpenConfirmModal(false);
                }}
                onConfirmClick={async () => {
                    return await deleteSavedReportService(selectedReport !== null ? selectedReport["id"] : "");
                }}
                title={t('PageReport.SavedReportTable.ConfirmModal.Title')}
                description={t('PageReport.SavedReportTable.ConfirmModal.Description') + t('Common.Space') + "[" + (selectedReport !== null ? selectedReport["name"] : "") + "]"} />
        </Row>
    );
}

export default ReportListTable;