module.exports = {
    logo_height: "26",
    EVENT_TYPE: {
        FALL: "Fall"
    },
    MONITOR_STATUS: {
        TOILET: {
            IDLE: "TOILET_IDLE", 
            USING: "TOILET_USING", 
            USING_OT: "TOILET_USING_OT", 
            FALL: "TOILET_FALL", 
            FROZEN_FALL: "TOILET_FROZEN_FALL", 
            FROZEN_USING_OT: "TOILET_FROZEN_USING_OT"
        },
        BED: {
            IDLE: "BED_IDLE", 
            FALL: "BED_FALL", 
            FROZEN_FALL: "BED_FROZEN_FALL", 
        }
    },
    MONITOR_TYPE: {
        TOILET: 2,
        BED: 1
    },
    MONITOR_PERIOD: {
        "24hours": "24hours",
        "MidNight": "MidNight",
        "AppliedRule": "AppliedRule",
        "SelfDefined": "SelfDefined"
    },
    REPORT_PERIOD: {
        M1: "1M",
        M3: "3M",
        M6: "6M",
        ALL: "ALL"
    },
    LOCATION_TYPE: {
        ROOM: "ROOM",
        TOILET: "TOILET"
    },
    DISPLAY_MODE: {
        COLUMN_2: "column_2"
    },
    REPORT_PAGE_SIZE: 10,
    GENDER: {
        MALE: "M",
        FEMALE: "F",
        ALL: "A"
    },
    SORT: {
        ASE: "asc",
        DESC: "desc"
    }
}