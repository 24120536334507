import { createSlice } from "@reduxjs/toolkit";
import { MONITOR_TYPE, REPORT_PAGE_SIZE, SORT } from "../../common/constants";
import { REPORT_LIST_COL_NAME } from "../../pages/Report/ReportListTable";

export const initialState = {
  pending: {
    totalEvents: {
      BED: 0,
      TOILET: 0,
    },
    events: []
  },
  events: {},
  error: {},
  messages: {
  },
  report: {
    reportID: null,
    isModifiedReportID: false,
    reportName: "",
    dateRange: null, //  [new Date(), new Date().setMonth(new Date().getMonth() - 1)]
    selectedMultiFloorPlan: null,
    selectedMultiMonitorType: null,
    selectedMultiMonitorStatus: null,
    eventIDs:[],
    events: {},
    changes: {},
    totalEvents: 0,
    pageNumber: "1",
    pageSize: REPORT_PAGE_SIZE,
    lastUpdated: new Date().getTime(),
    isLoading: false
  },
  savedReportList: {
    data: [],
    totalReports: 0,
    pageNumber: 1,
    pageSize: REPORT_PAGE_SIZE,
    searchText: "",
    sortColumn: REPORT_LIST_COL_NAME.CREATED_AT,
    sortDirection: SORT.DESC,
    lastUpdated: new Date().getTime()
  }
};

const eventSlice = createSlice({
  name: 'Event',
  initialState,
  reducers: {
    apiError(state, action) {
      state.error = action.payload;
    },
    getPendingEventsSuccess(state, action) {
      state.pending = action.payload.pending;
      state.events = action.payload.events;
    },
    getEventMessageSuccess(state, action) {
      state.messages[action.payload.eventID] = action.payload.result;
    },
    createEventMessageSuccess(state, action) {
      state.messages[action.payload.eventID] = action.payload.result;
    },
    setReportUpdate: (state, action) => {
      let event = {...state.events[action.payload]};
      if (event !== undefined) {
        let newEvents = [...state.pending.events];
        let index = newEvents.indexOf(action.payload);
        let totalBed = state.pending.totalEvents.BED;
        let totalToilet = state.pending.totalEvents.TOILET;
        if (index !== -1) {
          newEvents.splice(index, 1);
          if (event.monitorTypeID === MONITOR_TYPE.TOILET) {
            totalToilet = totalToilet - 1;
          }
          else if (event.monitorTypeID === MONITOR_TYPE.BED) {
            totalBed = totalBed - 1;
          }
        }
        return {
          ...state,
          pending: {
            totalEvents: {
              BED: totalBed,
              TOILET: totalToilet,
            },
            events: newEvents
          },
        };
      }
      return state;
    },
    addReportUpdate: (state, action) => {
      let newPendingEvents = [...new Set([...action.payload.pending.events, ...state.pending.events])];
      let totalBed = state.pending.totalEvents.BED + action.payload.pending.totalEvents.BED;
      let totalToilet = state.pending.totalEvents.TOILET + action.payload.pending.totalEvents.TOILET;
      let newEvents = {...state.events};
      for (let key in action.payload.events) {
        newEvents[key] = action.payload.events[key];
      }
       
      return {
        ...state,
        pending: {
          totalEvents: {
            BED: totalBed,
            TOILET: totalToilet,
          },
          events: newPendingEvents
        },
        events: newEvents
      };
    },
    getEventsReportSuccess(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          ...action.payload.data,
          isLoading: false
        }
      }
    },
    getEventsReportByIDSuccess(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          ...action.payload
        }
      }
    },
    getEventsReportListSuccess(state, action) {
      console.log(action);
      return {
        ...state,
        savedReportList: {
          ...state.savedReportList,
          ...action.payload.data
        }
      }
    },
    setReportEventDateRange(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          dateRange: [...action.payload],
          isModifiedReportID: true,
          pageNumber: "1",
        }
      }
    },
    setReportEventSelectedMultiFloorPlan(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          selectedMultiFloorPlan: [...action.payload],
          isModifiedReportID: true,
          pageNumber: "1",
        }
      }
    },
    setReportEventSelectedMultiMonitorType(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          selectedMultiMonitorType: [...action.payload],
          isModifiedReportID: true,
          pageNumber: "1",
        }
      }
    },
    setReportEventSelectedMultiMonitorStatus(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          selectedMultiMonitorStatus: [...action.payload],
          isModifiedReportID: true,
          pageNumber: "1",
        }
      }
    },
    setReportPage(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          pageNumber: action.payload,
        }
      }
    },
    updateSelectedReportID(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          reportID: action.payload,
          isModifiedReportID: false,
          reportName: action.payload.name
        }
      }
    },
    saveEventsReportSuccess(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          reportID: action.payload.data,
          isModifiedReportID: false,
          reportName: action.payload.data.name
        }
      }
    },
    updateSavedEventListTableParameters(state, action) {
      console.log(action);
      return {
        ...state,
        savedReportList: {
          ...state.savedReportList,
          ...action.payload
        }
      }
    },
    resetSavedEventListTableParameters(state) {
      return {
        ...state,
        savedReportList: {
          ...initialState.savedReportList,
          lastUpdated: new Date().getTime()
        }
      }
    },
    resetEventTableParameters(state) {
      return {
        ...state,
        report: {
          ...initialState.report,
          lastUpdated: new Date().getTime()
        }
      }
    },
    setSaveReportName(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          reportName: action.payload
        }
      }
    },
    setIsReportLoading(state, action) {
      console.log(action);
      return {
        ...state,
        report: {
          ...state.report,
          isLoading: action.payload
        }
      }
    },
    resetState: () => initialState
  }
});

export const {
  apiError,
  getPendingEventsSuccess,
  getEventMessageSuccess,
  createEventMessageSuccess,
  setReportUpdate,
  addReportUpdate,
  getEventsReportSuccess,
  getEventsReportListSuccess,
  setReportEventDateRange,
  setReportEventSelectedMultiFloorPlan,
  setReportEventSelectedMultiMonitorType,
  setReportEventSelectedMultiMonitorStatus,
  setReportPage,
  getEventsReportByIDSuccess,
  updateSelectedReportID,
  saveEventsReportSuccess,
  updateSavedEventListTableParameters,
  resetSavedEventListTableParameters,
  resetEventTableParameters,
  setSaveReportName,
  setIsReportLoading,
  resetState,
} = eventSlice.actions

export default eventSlice.reducer;